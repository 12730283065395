<template>
  <div class="container">
    <el-row class="login-box-container">
      <el-col :span="6" class="login-box">
        <el-form ref="form" :model="form">
          <el-form-item>
            <div class="header">
              <img src="/image/logo-login.png" alt="">
              {{$t('login.form.title')}}
            </div>
          </el-form-item>
          <el-form-item>
            <el-input :placeholder="$t('login.form.emailInput')" v-model="form.name" required></el-input>
          </el-form-item>
          <el-form-item>
            <el-input :placeholder="$t('login.form.secretInput')" v-model="form.secret" show-password required></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" style="width: 100%;">{{$t('login.form.login')}}</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>

  </div>

</template>

<script>
import NvNetwork from "../api/NvNetwork";
import NvUtils from "@/api/NvUtils";

export default {
  data() {
    return {
      form: {
        name: '',
        secret: '',
        error: false
      }
    }
  },
  methods: {
    onSubmit() {
      let network = new NvNetwork()
      network.login(this.form.name, this.form.secret).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          localStorage.userToken = response.data.token
          localStorage.email = this.form.name
          localStorage.role = response.data.role
          localStorage.menuIds = response.data.pages
          this.$router.push('/console/welcome')
        } else {
          NvUtils.clearStorage()
          this.error = true
          this.$message.error(this.$t('login.error.loginFailed'));
        }
      }).catch(error => {
        NvUtils.clearStorage()
        this.error = true
        this.$message.error(this.$t('login.error.loginFailed'));
      })
    },
  },
  name: "NvLogin",
  mounted() {
    let network = new NvNetwork()
    let apiSts = network.data().webApi_sts_info
  },
  created() {
    NvUtils.clearStorage()
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
}

.el-row {
  width: 100%;
  height: 100%;
}

.el-form {
  margin: auto;
}

.header {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 24px;

  img {
    transform: scale(0.5);
    margin: 0 -10px 0 -63px;
  }
}

.login-box {
  padding: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  border-radius: 10px;
  background-color: white
}

.login-box-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

</style>
