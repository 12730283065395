<template>
  <div class="layout">
    <template v-if="loggedIn">
      <el-container class="container">
        <NvSideMenu class="side-menu"></NvSideMenu>
        <el-container class="body" direction="vertical">
          <NvHeader :email=this.email style="width: 100%;height: 60px;"></NvHeader>
          <transition name="fade-transform" mode="out-in">
            <!-- <keep-alive> -->
            <router-view class="view" v-if="isRouterAlive" style="width: 100%" :key="key"></router-view>
            <!-- </keep-alive> -->
          </transition>
        </el-container>
      </el-container>
    </template>
    <template v-if="!loggedIn">
      <router-view class="view">not logged in</router-view>
    </template>
  </div>
</template>

<script>
import NvSideMenu from "./NvSideMenu.vue";
import NvHeader from "./NvHeader.vue";
import NvUtils from "@/api/NvUtils";

export default {
  name: "NvLayout",
  components: {
    'NvHeader': NvHeader,
    'NvSideMenu': NvSideMenu
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler(val) {
        this.active = val
        this.email = localStorage.email
        this.loggedIn = !!localStorage.userToken
        if (this.$route.path == "/") {
          NvUtils.clearStorage()
          this.$router.push('/login')
        }
        if (this.$route.path == "/login") {
          NvUtils.clearStorage()
          this.loggedIn = !!localStorage.userToken
          this.reload();
        }
      }
    }
  },
  data() {
    return {
      email: "",
      loggedIn: false,
      isRouterAlive: true,
      audioWave: null
    }
  },
  created() {
  },
  mounted() {
  },
  computed: {
    key() {
      return this.$route.path
    }
  },
  provide() {
    return {
      reload: this.reload
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => (this.isRouterAlive = true))
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  color: #2c3e50;
  background-color: #F0F2F5;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 0;
  background-color: #F0F2F5;
}

$mainColor: #545c64;
body {
  position: relative;
  height: auto;
  bottom: 0;
  background-color: #F0F2F5;
  margin: 0;
  height: 100%;
}

.el-header {
  display: flex;
  align-items: center;
  color: white;

  img {
    transform: scale(0.5);
    margin: 0 -10px 0 -63px;
  }
}

.el-aside {
  background-color: $mainColor;
}

.is-vertical {
  min-height: 100vh;
}

.select-option-horizon /deep/ .el-select-dropdown__list {
  display: flex;
  flex-wrap: wrap;
  width: 360px;
  padding-left: 5px;
  padding-right: 5px;
}

.select-option-horizon /deep/ .el-select-dropdown__item {
  padding: 0 5px;
}

.body {
  position: relative;
  height: auto;
  bottom: 0;
  margin-top: 0px;
  background-color: #F0F2F5;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, .1) inset;
}

.side-menu {
  height: auto;
  width: 200px;
  overflow-y: scroll;
  background-color: $mainColor;
}

.side-menu::-webkit-scrollbar {
  display: none;
}
</style>
