<template>
  <el-menu :background-color="asideBackgroundColor"
           text-color="#fff"
           :collapse="isCollapse"
           :default-openeds="openeds"
           :collapse-transition="false"
           active-text-color="#ffd04b">
    <el-menu-item index="0" @click="onMenuClicked(0)" v-if="menuInfo.show_home">
      <i class="el-icon-menu"></i>
      <span slot="title">{{ $t("sideMenu.welcome") }}</span>
    </el-menu-item>
    <el-submenu index="1" v-if="menuInfo.show_publicMaterial">
      <template slot="title">
        <i class="el-icon-caret-right"></i>
        <span>{{ $t("sideMenu.material.material") }}</span>
      </template>
      <el-menu-item index="1-1" @click="onMenuClicked(101)" v-if="menuInfo.show_materialTheme">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.theme") }}</span>
      </el-menu-item>
      <el-menu-item index="1-2" @click="onMenuClicked(102)" v-if="menuInfo.show_materialFilter">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.videoFx") }}</span>
      </el-menu-item>
      <el-menu-item index="1-3" @click="onMenuClicked(103)" v-if="menuInfo.show_materialSpecialFilter">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.specialFilter") }}</span>
      </el-menu-item>
      <el-menu-item index="1-4" @click="onMenuClicked(104)" v-if="menuInfo.show_materialCaption">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.caption") }}</span>
      </el-menu-item>
      <el-menu-item index="1-5" @click="onMenuClicked(105)" v-if="menuInfo.show_materialAnimatedSticker">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.sticker") }}</span>
      </el-menu-item>
      <el-menu-item index="1-6" @click="onMenuClicked(106)" v-if="menuInfo.show_materialTransition">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.transition") }}</span>
      </el-menu-item>
      <el-menu-item index="1-7" @click="onMenuClicked(107)" v-if="menuInfo.show_materialParticle">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.particle") }}</span>
      </el-menu-item>
      <el-menu-item index="1-8" @click="onMenuClicked(108)" v-if="menuInfo.show_materialCompoundCaption">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.compoundCaption") }}</span>
      </el-menu-item>
      <el-menu-item index="1-10" @click="onMenuClicked(110)" v-if="menuInfo.show_materialMusic">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.music") }}</span>
      </el-menu-item>
      <el-menu-item index="1-11" @click="onMenuClicked(111)" v-if="menuInfo.show_materialFont">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.font") }}</span>
      </el-menu-item>
<!--      <el-menu-item index="1-12" @click="onMenuClicked(112)" v-if="menuInfo.show_materialRichText">-->
<!--        <i class="el-icon-menu"></i>-->
<!--        <span>{{ $t("sideMenu.material.richText") }}</span>-->
<!--      </el-menu-item>-->
      <el-menu-item index="1-13" @click="onMenuClicked(113)" v-if="menuInfo.show_materialTemplate">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.template") }}</span>
      </el-menu-item>
      <el-menu-item index="1-14" @click="onMenuClicked(114)" v-if="menuInfo.show_materialClipAnimationVideoFx">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.clipAnimationVideoFx") }}</span>
      </el-menu-item>
      <el-menu-item index="1-15" @click="onMenuClicked(115)" v-if="menuInfo.show_materialBackgroundImage">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.backgroundImage") }}</span>
      </el-menu-item>
      <el-menu-item index="1-16" @click="onMenuClicked(116)" v-if="menuInfo.show_materialCaptionAnimation">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.captionAnimation") }}</span>
      </el-menu-item>
      <el-menu-item index="1-17" @click="onMenuClicked(117)" v-if="menuInfo.show_materialAnimatedStickerAnimation">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.material.animatedStickerAnimation") }}</span>
      </el-menu-item>
    </el-submenu>
    <el-menu-item index="4" @click="onMenuClicked(4)" v-if="menuInfo.show_job">
      <i class="el-icon-menu"></i>
      <span>{{ $t("sideMenu.job") }}</span>
    </el-menu-item>
    <el-submenu index="5" v-if="menuInfo.show_monitor">
      <template slot="title">
        <i class="el-icon-caret-right"></i>
        <span>{{ $t("sideMenu.monitor.title") }}</span>
      </template>
      <el-menu-item index="51" @click="onMenuClicked(51)">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.monitor.service") }}</span>
      </el-menu-item>
      <el-menu-item index="52" @click="onMenuClicked(52)">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.monitor.email") }}</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="6" v-if="menuInfo.show_splitVideo">
      <template slot="title">
        <i class="el-icon-caret-right"></i>
        <span>{{ $t("sideMenu.splitVideo.title") }}</span>
      </template>
      <el-menu-item index="61" @click="onMenuClicked(61)">
        <i class="el-icon-menu"></i>
        <span>{{ $t("sideMenu.splitVideo.newsSplit") }}</span>
      </el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
import NvNetwork from "../../api/NvNetwork";
import NvUtils from "@/api/NvUtils";
import { mapGetters } from 'vuex'

export default {
  name: "NvSideMenu",
  inject: ['reload'],
  computed: {
    ...mapGetters([
      'sidebar'
    ]),
    isCollapse() {
      return !this.sidebar.opened
    }
  },
  data() {
    return {
      openeds: ['0'],
      menuInfo: {
        show_home: true,
        show_publicMaterial: true,
        show_materialTheme: true,
        show_materialFilter: true,
        show_materialSpecialFilter: true,
        show_materialCaption: true,
        show_materialAnimatedSticker: true,
        show_materialTransition: true,
        show_materialParticle: true,
        show_materialCaptureScene: true,
        show_materialARScene: true,
        show_materialCompoundCaption: true,
        show_materialPhotoAlbum: true,
        show_materialMIMO: true,
        show_materialVLog: true,
        show_materialTemplate: true,
        show_materialRichText: true,
        show_materialFont: true,
        show_materialMusic: true,
        show_materialClipAnimationVideoFx: true,
        show_materialBackgroundImage: true,
        show_materialCaptionAnimation: true,
        show_materialAnimatedStickerAnimation: true,
        show_job: true,
        show_monitor: true,
        show_splitVideo: true
      },
      asideBackgroundColor: "#001529",
    }
  },
  created() {
    this.menuInfo.show_home = NvUtils.isMenuExist(NvNetwork.pageId_home)
    this.menuInfo.show_publicMaterial = NvUtils.isMenuExist(NvNetwork.pageId_publicMaterial)
    this.menuInfo.show_materialTheme = NvUtils.isMenuExist(NvNetwork.pageId_materialTheme)
    this.menuInfo.show_materialFilter = NvUtils.isMenuExist(NvNetwork.pageId_materialFilter)
    this.menuInfo.show_materialSpecialFilter = NvUtils.isMenuExist(NvNetwork.pageId_materialFilter)
    this.menuInfo.show_materialCaption = NvUtils.isMenuExist(NvNetwork.pageId_materialCaption)
    this.menuInfo.show_materialAnimatedSticker = NvUtils.isMenuExist(NvNetwork.pageId_materialAnimatedSticker)
    this.menuInfo.show_materialTransition = NvUtils.isMenuExist(NvNetwork.pageId_materialTransition)
    this.menuInfo.show_materialParticle = NvUtils.isMenuExist(NvNetwork.pageId_materialParticle)
    this.menuInfo.show_materialCaptureScene = NvUtils.isMenuExist(NvNetwork.pageId_materialCaptureScene)
    this.menuInfo.show_materialARScene = NvUtils.isMenuExist(NvNetwork.pageId_materialARScene)
    this.menuInfo.show_materialCompoundCaption = NvUtils.isMenuExist(NvNetwork.pageId_materialCompoundCaption)
    this.menuInfo.show_materialPhotoAlbum = NvUtils.isMenuExist(NvNetwork.pageId_materialPhotoAlbum)
    this.menuInfo.show_materialMIMO = NvUtils.isMenuExist(NvNetwork.pageId_materialMIMO)
    this.menuInfo.show_materialVLog = NvUtils.isMenuExist(NvNetwork.pageId_materialVLog)
    this.menuInfo.show_materialTemplate = NvUtils.isMenuExist(NvNetwork.pageId_materialTemplate)
    this.menuInfo.show_materialFont = NvUtils.isMenuExist(NvNetwork.pageId_materialFont)
    this.menuInfo.show_materialMusic = NvUtils.isMenuExist(NvNetwork.pageId_materialMusic)
    this.menuInfo.show_materialClipAnimationVideoFx = NvUtils.isMenuExist(NvNetwork.pageId_materialClipAnimationVideoFx)
    // this.menuInfo.show_materialBackgroundImage = NvUtils.isMenuExist(NvNetwork.pageId_materialBackgroundImage)
    // this.menuInfo.show_materialCaptionAnimation = NvUtils.isMenuExist(NvNetwork.pageId_materialCaptionAnimation)
    // this.menuInfo.show_materialAnimatedStickerAnimation = NvUtils.isMenuExist(NvNetwork.pageId_materialAnimatedStickerAnimation)
    this.menuInfo.show_job = NvUtils.isMenuExist(NvNetwork.pageId_job)
    this.menuInfo.show_splitVideo = NvUtils.isMenuExist(NvNetwork.pageId_splitVideo)

    this.buttonStyle = this.unCollapseButtonStyle
    this.asideContainerStyle = this.asideContainerStyleUncollapse
  },
  methods: {
    onMenuClicked(index) {
      if (index == 0) {
        this.$router.push('/console/welcome')
      } else if (index == 1) {
      } else if (index == 3) {
      } else if (index == 4) {
        this.$router.push('/console/task')
      } else if (index == 51) {
        this.$router.push('/monitor/service')
      } else if (index == 52) {
        this.$router.push('/monitor/email')
      } else if (index == 61) {
        this.$router.push('/splitVideo/newsSplit')
      } else if (index == 101) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeTheme}})
        this.reload();
      } else if (index == 102) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeFilter}})
        this.reload();
      } else if (index == 103) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeSpecialFilter}})
        this.reload();
      } else if (index == 104) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeCaption}})
        this.reload();
      } else if (index == 105) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeAnimatedSticker}})
        this.reload();
      } else if (index == 106) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeTransition}})
        this.reload();
      } else if (index == 107) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeParticle}})
        this.reload();
      } else if (index == 108) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeCompoundCaption}})
        this.reload();
      } else if (index == 110) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeMusic}})
        this.reload();
      } else if (index == 111) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeFont}})
        this.reload();
      } else if (index == 112) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeRichText}})
        this.reload();
      } else if (index == 113) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeTemplate}})
        this.reload();
      } else if (index == 114) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeClipAnimationVideoFx}})
        this.reload();
      } else if (index == 115) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeBackgroundImage}})
        this.reload();
      } else if (index == 116) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeCaptionAnimation}})
        this.reload();
      } else if (index == 117) {
        this.$router.push({path: '/console/material', query: {type: NvNetwork.materialTypeAnimatedStickerAnimation}})
        this.reload();
      }
    }
  },
}
</script>

<style scoped lang="scss">
.el-menu {
  border-right: none;
}

.el-menu--collapse {
  width: 64px !important;
}
</style>
