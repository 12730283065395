import Vue from 'vue'
import VueI18n from 'vue-i18n'

import zh from './config/zh'
import en from './config/en'
import NvUtils from "@/api/NvUtils.js";

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: getLocale(),
  messages: {
    zh,
    en,
  }
})

function getLocale () {
  var lang = navigator.language
  console.log('Locale Language：', lang)
  console.log('Storage language', sessionStorage.language)
  if (sessionStorage.language === undefined) {
    lang = lang.substr(0, 2)
    NvUtils.setLanguage(lang)
    if (lang === 'zh') {
      return 'zh'
    } else {
      return 'en'
    }
  } else {
    NvUtils.setLanguage(sessionStorage.language)
    return sessionStorage.language
  }
}

export default i18n
