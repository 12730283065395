import { render, staticRenderFns } from "./NvLayout.vue?vue&type=template&id=b6d7c382&scoped=true&"
import script from "./NvLayout.vue?vue&type=script&lang=js&"
export * from "./NvLayout.vue?vue&type=script&lang=js&"
import style0 from "./NvLayout.vue?vue&type=style&index=0&id=b6d7c382&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6d7c382",
  null
  
)

export default component.exports