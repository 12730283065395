import NvNetwork from "@/api/NvNetwork";

let uploadDomId = 'material-upload'
let pic = { types: [], assets: [] }
let video = { types: [], assets: [] }
let audio = { types: [], assets: [] }
let nvsSign = ''

export async function getConfig () {

    window._XN_appCfg = {
        appId: getUploadAppId(),
        appSecret: getUploadAppSecret()
    }
}

function stream (key, file, callback, error) {
    key = key.replace(/^\/+/, '')

    var path = '/material/' + key
    const info = {
        targetPath: path,
        appId: window._XN_appCfg.appId,
        appSecret: window._XN_appCfg.appSecret
    }
    const name = key.split('/').pop()
    let network = new NvNetwork()
    const _url = `${network.data().upload_stream}?targetPath=${info.targetPath}&appId=${info.appId}&appSecret=${info.appSecret}`
    var _xhr = new XMLHttpRequest
    _xhr.ontimeout = function (e) {
        console.error('The request for ' + _url + ' timed out.')
        if (error && typeof error === 'function') error(e)
    }
    _xhr.onreadystatechange = function () {
        if (_xhr.readyState != 4 || _xhr.status < 200) return false
        let data = JSON.parse(_xhr.responseText)
        if (!data.success) return
        if (callback && typeof callback === 'function') {
            console.log(data, '完成！！', NvNetwork.uploadMountHost)
            callback({
                finish: true,
                progress: 100,
                url: data.path.replace(getStorageLocalPath(), getStorageCDNHost())
            })
        }
    }
    _xhr.onerror = (e) => {
        if (error && typeof error === 'function') {
            error(e)
        }
    }
    _xhr.upload.onprogress = (e) => {
        const { loaded, total } = e
        if (callback && typeof callback === 'function') {
            callback({finish: false, progress: (loaded / total * 100).toFixed(2)})
        }
    }
    _xhr.timeout = 60 * 2000
    _xhr.open('POST', _url, !0)
    _xhr.send(file)
}
export function streamUpload (key, file, progressCB) {
    return new Promise((resolve, reject) => {
        stream(key, file, e => {
            if (progressCB && typeof progressCB === 'function') {
                progressCB({ ...e, progress: e.finish ? 100 : e.progress})
            }
            if (e.finish) {
                resolve(e.url)
            }
        }, reject)
    })
}
