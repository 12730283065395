<template>
  <el-main>
    <el-card>
      <el-form :model="search">
        <el-form-item>
          <el-button type="primary" @click="addEmail">{{ $t('monitor.search.addEmail') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :data="emailList" style="width: 100%" :empty-text="$t('header.dialog.emptyText')">
        <el-table-column
            :sortable="false"
            align="center"
            v-for="header in headers"
            :key="header.prop"
            :prop="header.prop"
            :label="header.label"
            :min-width="header.minWidth"
            :width="header.width">
        </el-table-column>
        <el-table-column fixed="right"
                          :label="$t('monitor.table.operation')"
                          align="center"
                          width="180">
          <template slot-scope="scope">
            <el-button @click="updateEmail(scope.row)" type="text" size="small">{{ $t('monitor.table.update') }}</el-button>
            <el-button @click="removeEmail(scope.row)" type="text" size="small">{{ $t('monitor.table.remove') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog :title="$t('monitor.dialog.addEmail.title')" width="30%" :visible.sync="addEmailDialogVisible"
               :close-on-click-modal="false"
               :destroy-on-close="true">
      <el-form :model="emailInfo">
        <el-form-item>
          <el-input :placeholder="$t('monitor.dialog.addEmail.emailInput')" v-model="emailInfo.email" required></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="confirmEmail" style="width: 100%;">{{$t('monitor.dialog.addEmail.confirm')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="$t('monitor.dialog.updateEmail.title')" width="30%" :visible.sync="updateEmailDialogVisible"
               :close-on-click-modal="false"
               :destroy-on-close="true">
      <el-form :model="updateEmailInfo">
        <el-form-item>
          <el-input :placeholder="$t('monitor.dialog.updateEmail.emailInput')" v-model="updateEmailInfo.email" required></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="updateEmailInfo.status" clearable :placeholder="$t('monitor.dialog.updateEmail.statusInput')"
                     style="width: 100%;float: left;">
            <el-option
                v-for="item in emailStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="confirmUpdateEmail" style="width: 100%;">{{$t('monitor.dialog.addEmail.confirm')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-main>
</template>

<script>
import NvNetwork from "@/api/NvNetwork.js";
import NvUtils from "@/api/NvUtils.js";

export default {
  name: "NvEmail",
  data() {
    return {
      search: {},
      addEmailDialogVisible: false,
      updateEmailDialogVisible: false,
      emailInfo: {
        email: "",
        status: 1
      },
      updateEmailInfo: {
        email: "",
        status: 1
      },
      emailList: [],
      headers: [
        {prop: 'id', label: this.$t('monitor.table.columnId'), minWidth: 100},
        {prop: 'email', label: this.$t('monitor.table.columnEmail'), minWidth: 100},
        {prop: 'createdAt', label: this.$t('monitor.table.columnCreatedAt'), minWidth: 100},
        {prop: 'statusDisplayName', label: this.$t('monitor.table.columnStatus'), minWidth: 100},
      ],
      emailStatus: [
        {value: 1, label: this.$t('monitor.table.email.status.send')},
        {value: 0, label: this.$t('monitor.table.email.status.dontSend')},
      ]
    }
  },
  mounted() {
    this.getEmailList()
  },
  methods: {
    addEmail() {
      this.addEmailDialogVisible = true
    },
    confirmEmail() {
      let network = new NvNetwork()
      network.monitorEmailAdd(this.emailInfo.email).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.$message({
            message: response.message,
            type: 'success'
          })
        } else {
          this.$message.error(response.message)
        }
        this.getEmailList()
      }).catch(error => {
        console.log(error)
      })
      this.addEmailDialogVisible = false
    },
    updateEmail(emailInfo) {
      this.updateEmailDialogVisible = true
      this.updateEmailInfo = NvUtils.deepCopy(emailInfo)
    },
    confirmUpdateEmail() {
      let network = new NvNetwork()
      network.monitorEmailUpdate(this.updateEmailInfo.id, this.updateEmailInfo.email, this.updateEmailInfo.status).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.$message({
            message: response.message,
            type: 'success'
          })
        } else {
          this.$message.error(response.message)
        }
        this.getEmailList()
      }).catch(error => {
        console.log(error)
      })
      this.updateEmailDialogVisible = false
    },
    getEmailList() {
      let network = new NvNetwork()
      network.monitorEmailList().then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.emailList = response.data
          this.emailList.forEach((item, index) => {
            this.emailStatus.forEach(status => {
              if (item.status == status.value) {
                item.statusDisplayName = status.label
              }
            })
            this.$set(this.emailList, item, index)
          })
        } else {
          this.$message.error(response.message)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    removeEmail(emailInfo) {
      let network = new NvNetwork()
      network.monitorEmailRemove(emailInfo.id).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.$message({
            message: response.message,
            type: 'success'
          })
        } else {
          this.$message.error(response.message)
        }
        this.getEmailList()
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.el-main {
  width: 200px;
}
::v-deep .el-form-item {
  margin-bottom: 0px;
}
.el-card {
  margin-bottom: 20px;
}
::v-deep .el-dialog .el-form-item {
  margin-bottom: 10px;
}
</style>
