<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app'
}
</script>

<style>
#app {
  color: #2c3e50;
  background-color: aliceblue;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 0;
  background-color: aliceblue;
}

.body {
  position: relative;
  width: 100%;
  height: 100%;
  bottom: 0;
  margin-top: 0px;
  background-color: #F0F2F5;
  box-shadow: 0 0 5px 5px rgba(0,0,0,.1) inset;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.side-menu {
  height: 100%;
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  display: none;
}
</style>
