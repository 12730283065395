<template>
  <el-main style="background-color: #F0F2F5">
    <el-row :gutter="20" style="margin-bottom: 20px;">
      <el-col :span="6">
        <el-card>
          <el-row>{{ profileData.onlineUserCount }}</el-row>
          <el-row>{{ $t('welcome.profileData.onlineUserCount') }}</el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <el-row>{{ profileData.authServerIps }}</el-row>
          <el-row>{{ $t('welcome.profileData.authServerIps') }}</el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <el-row>{{ profileData.bsEditConnectionCount }}</el-row>
          <el-row>{{ $t('welcome.profileData.bsEditConnectionCount') }}</el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <el-row>{{ profileData.bsEditConcurrentCount }}</el-row>
          <el-row>{{ $t('welcome.profileData.bsEditConcurrentCount') }}</el-row>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="6" v-show="showLiveEditView()">
        <el-card>
          <el-row>{{ profileData.liveEditConcurrentCount }}</el-row>
          <el-row>{{ $t('welcome.profileData.liveEditConcurrentCount') }}</el-row>
        </el-card>
      </el-col>
      <el-col :span="6" v-show="showLiveEditView()">
        <el-card>
          <el-row>{{ profileData.liveEditConnectionCount }}</el-row>
          <el-row>{{ $t('welcome.profileData.liveEditConnectionCount') }}</el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <el-row>{{ profileData.authStartDate }}</el-row>
          <el-row>{{ $t('welcome.profileData.authStartDate') }}</el-row>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <el-row>{{ profileData.authEndDate }}</el-row>
          <el-row>{{ $t('welcome.profileData.authEndDate') }}</el-row>
        </el-card>
      </el-col>
    </el-row>
  </el-main>
</template>

<script>
import NvNetwork from "@/api/NvNetwork";
import NvUtils from "@/api/NvUtils";

export default {
  name: "NvWelcome",
  data() {
    return {
      profileData: {
        onlineUserCount: 0,
        authServerIps: "-",
        bsEditConnectionCount: 0,
        liveEditConnectionCount: 0,
        bsEditConcurrentCount: 0,
        liveEditConcurrentCount: 0,
        authStartDate: "-",
        authEndDate: "-"
      }
    }
  },
  created() {
    this.getUserOnlineCount()
    this.getBsEditAuthInfo()
  },
  methods: {
    showLiveEditView() {
      return showLiveEditView()
    },
    getUserOnlineCount() {
      let todayBegin = NvUtils.getTodayBeginString()
      let todayEnd = NvUtils.getTodayEndString()

      let network = new NvNetwork()
      network.getUserOnlineCount(todayBegin, todayEnd).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.profileData.onlineUserCount = response.data
        } else {
          this.$message.error(this.$t('welcome.error.userOnlineCountError'))
        }
      }).catch(error => {
        this.$message.error(this.$t('welcome.error.userOnlineCountError'))
      })
    },
    getBsEditAuthInfo() {
      let network = new NvNetwork()
      network.getBsEditAuthInfo().then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.profileData.authServerIps = NvUtils.isEmpty(response.data.authServerIps) ? "-" : response.data.authServerIps
          this.profileData.bsEditConcurrentCount = response.data.bsEditConcurrentCount
          this.profileData.liveEditConcurrentCount = response.data.liveEditConcurrentCount
          this.profileData.bsEditConnectionCount = response.data.bsEditConnectionCount
          this.profileData.liveEditConnectionCount = response.data.liveEditConnectionCount
          this.profileData.authStartDate = NvUtils.isEmpty(response.data.authStartDate) ? "-" : response.data.authStartDate
          this.profileData.authEndDate = NvUtils.isEmpty(response.data.authEndDate) ? "-" : response.data.authEndDate
        } else {
          this.$message.error(this.$t('welcome.error.bsEditAuthInfoError'))
        }
      }).catch(error => {
        this.$message.error(this.$t('welcome.error.bsEditAuthInfoError'))
      })
    }
  }
}
</script>

<style scoped>
.el-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  height: 150px;
}

.el-card .el-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 25px;
  justify-content: center;
  text-align: center;
}
</style>
