import NvNetwork from "@/api/NvNetwork";
import NvEnum from "@/api/NvEnum";
import Cookies from 'js-cookie';

export default {
    timestampToTime,
    getApiHost,
    isEmpty,
    generateUUID,
    getMaterialPackageKey,
    getMaterialName,
    extractPackageUUID,
    getFileExt,
    getUploadModuleName,
    isImageFile,
    hasMaterialVersion,
    getMaterialVersion,
    isLicFile,
    deepCopy,
    getTodayBeginString,
    getTodayEndString,
    getExecuteResultCodeDisplayName,
    getExecuteStateDisplayName,
    getJobTypeDisplayName,
    clearStorage,
    isValidMaterial,
    isMenuExist,
    isRichTextFile,
    isHtmlFile,
    isJsonFile,
    isVideoFile,
    extractFileName,
    getLanguage,
    setLanguage,
    isTemplateFont,
    isTemplateAsset,
    isMaterialFile,
    isZipFile
}

function isTemplateFont(fileName) {
    let pathSegments = fileName.split("\/")
    let pathName = pathSegments[0]
    if (pathName !== "font") {
        return false
    }
    let parts = pathSegments[1].split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "ttf" || name.toLowerCase() == "otf" || name.toLowerCase() == "ttc"
}

function isTemplateAsset(fileName) {
    let pathSegments = fileName.split("\/")
    let pathName = pathSegments[0]
    if (pathName !== "userAssets") {
        return false
    }
    let parts = pathSegments[1].split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "png" || name.toLowerCase() == "jpg" || name.toLowerCase() == "mp4"
}

/**
 * 时间戳转换
 * @param time
 * @returns {string}
 */
function timestampToTime(time) {
    var date = new Date(time * 1000) // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-'
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    var D = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate()) + ' '
    var h = date.getHours() + ':'
    var m = (date.getMinutes() < 10 ? '0' + (date.getMinutes()) : date.getMinutes()) + ':'
    var s = (date.getSeconds() < 10 ? '0' + (date.getSeconds()) : date.getSeconds())
    return Y + M + D + h + m + s
}

function getApiHost(isDebug, isOverseas) {
    if (isDebug) {
        return 'http://localhost:8088/'
    }
    if (isOverseas) {
        return 'https://ueapi.meishesdk.com:8443/'
    }
    return 'https://testeapi.meishesdk.com:8443/'
    // return 'http://localhost:8088/'
}

function isEmpty(string) {
    if (string === '' || string === null || string === undefined) {
        return true
    } else {
        return false
    }
}

/**
 * 生成唯一的UUID
 * @returns {string}
 */
function generateUUID() {
    var s = []
    var hexDigits = '0123456789abcdef'
    for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23] = '-'
    var uuid = s.join('')
    return uuid.toUpperCase()
}

// function getMaterialPackageKey(materialType) {
//     if (materialType == NvNetwork.materialTypeTheme) {
//         return "material/theme/"
//     } else if (materialType == NvNetwork.materialTypeFilter) {
//         return "material/videofx/"
//     } else if (materialType == NvNetwork.materialTypeCaption) {
//         return "material/captionstyle/"
//     } else if (materialType == NvNetwork.materialTypeAnimatedSticker) {
//         return "material/animatedsticker/"
//     } else if (materialType == NvNetwork.materialTypeTransition) {
//         return "material/videotransition/"
//     } else if (materialType == NvNetwork.materialTypeParticle) {
//         return "material/particle/"
//     } else if (materialType == NvNetwork.materialTypeARScene) {
//         return "material/arscene/"
//     } else if (materialType == NvNetwork.materialTypeCompoundCaption) {
//         return "material/compoundcaption/"
//     } else if (materialType == NvNetwork.materialTypeFont) {
//         return "material/font/"
//     } else if (materialType == NvNetwork.materialTypeMusic) {
//         return "material/music/"
//     } else {
//         return "material/unknown/"
//     }
// }

function getMaterialPackageKey(materialType) {
    if (materialType == NvNetwork.materialTypeTheme) {
        return "theme/"
    } else if (materialType == NvNetwork.materialTypeFilter) {
        return "video-fx/"
    } else if (materialType == NvNetwork.materialTypeCaption) {
        return "caption-style/"
    } else if (materialType == NvNetwork.materialTypeAnimatedSticker) {
        return "animated-sticker/"
    } else if (materialType == NvNetwork.materialTypeTransition) {
        return "transition/"
    } else if (materialType == NvNetwork.materialTypeParticle) {
        return "particle/"
    } else if (materialType == NvNetwork.materialTypeARScene) {
        return "ar-scene/"
    } else if (materialType == NvNetwork.materialTypeCompoundCaption) {
        return "compound-caption/"
    } else if (materialType == NvNetwork.materialTypeFont) {
        return "font/"
    } else if (materialType == NvNetwork.materialTypeMusic) {
        return "music/"
    } else if (materialType == NvNetwork.materialTypeTemplate) {
        return "template/"
    } else if (materialType == NvNetwork.materialTypeClipAnimationVideoFx) {
        return "clip-animation-video-fx/"
    } else if (materialType == NvNetwork.materialTypeBackgroundImage) {
        return "background-image/"
    } else if (materialType == NvNetwork.materialTypeCaptionAnimation) {
        return "caption-animation/"
    } else if (materialType == NvNetwork.materialTypeAnimatedStickerAnimation) {
        return "animated-sticker-animation/"
    } else {
        return "unknown/"
    }
}

function getMaterialName(materialType) {
    if (materialType == NvNetwork.materialTypeTheme) {
        return "sideMenu.material.theme"
    } else if (materialType == NvNetwork.materialTypeFilter) {
        return "sideMenu.material.videoFx"
    } else if (materialType == NvNetwork.materialTypeCaption) {
        return "sideMenu.material.caption"
    } else if (materialType == NvNetwork.materialTypeAnimatedSticker) {
        return "sideMenu.material.sticker"
    } else if (materialType == NvNetwork.materialTypeTransition) {
        return "sideMenu.material.transition"
    } else if (materialType == NvNetwork.materialTypeParticle) {
        return "sideMenu.material.particle"
    } else if (materialType == NvNetwork.materialTypeARScene) {
        return "ARScene"
    } else if (materialType == NvNetwork.materialTypeCompoundCaption) {
        return "sideMenu.material.compoundCaption"
    } else if (materialType == NvNetwork.materialTypeFont) {
        return "sideMenu.material.font"
    } else if (materialType == NvNetwork.materialTypeMusic) {
        return "sideMenu.material.music"
    } else if (materialType == NvNetwork.materialTypeRichText) {
        return "sideMenu.material.richText"
    } else if (materialType == NvNetwork.materialTypeTemplate) {
        return "sideMenu.material.template"
    } else if (materialType == NvNetwork.materialTypeClipAnimationVideoFx) {
        return "sideMenu.material.clipAnimationVideoFx"
    } else if (materialType == NvNetwork.materialTypeSpecialFilter) {
        return "sideMenu.material.specialFilter"
    } else if (materialType == NvNetwork.materialTypeBackgroundImage) {
        return "sideMenu.material.backgroundImage"
    } else if (materialType == NvNetwork.materialTypeCaptionAnimation) {
        return "sideMenu.material.captionAnimation"
    } else if (materialType == NvNetwork.materialTypeAnimatedStickerAnimation) {
        return "sideMenu.material.animatedStickerAnimation"
    } else {
        return "Unknown"
    }
}

function getUploadModuleName(materialType) {
    if (materialType == NvNetwork.materialTypeTheme) {
        return NvEnum.uploadModule.material_theme
    } else if (materialType == NvNetwork.materialTypeFilter) {
        return NvEnum.uploadModule.material_videofx
    } else if (materialType == NvNetwork.materialTypeCaption) {
        return NvEnum.uploadModule.material_captionstyle
    } else if (materialType == NvNetwork.materialTypeAnimatedSticker) {
        return NvEnum.uploadModule.material_animatedsticker
    } else if (materialType == NvNetwork.materialTypeTransition) {
        return NvEnum.uploadModule.material_videotransition
    } else if (materialType == NvNetwork.materialTypeParticle) {
        return NvEnum.uploadModule.material_particle
    } else if (materialType == NvNetwork.materialTypeCompoundCaption) {
        return NvEnum.uploadModule.material_compoundcaption
    } else if (materialType == NvNetwork.materialTypeFont) {
        return NvEnum.uploadModule.material_font
    } else if (materialType == NvNetwork.materialTypeMusic) {
        return NvEnum.uploadModule.material_music
    } else if (materialType == NvNetwork.materialTypeTemplate) {
        return NvEnum.uploadModule.material_template
    } else if (materialType == NvNetwork.materialTypeClipAnimationVideoFx) {
        // 作为滤镜类型，上传请求添加额外的参数hideType
        return NvEnum.uploadModule.material_videofx
    } else if (materialType == NvNetwork.materialTypeSpecialFilter) {
        // 作为滤镜类型，上传请求添加额外的参数hideType
        return NvEnum.uploadModule.material_videofx
    } else if (materialType == NvNetwork.materialTypeBackgroundImage) {
        return NvEnum.uploadModule.material_backgroundimage
    } else if (materialType == NvNetwork.materialTypeCaptionAnimation) {
        // 作为字幕类型，上传请求添加额外的参数hideType
        return NvEnum.uploadModule.material_captionstyle
    } else if (materialType == NvNetwork.materialTypeAnimatedStickerAnimation) {
        // 作为贴纸类型，上传请求添加额外的参数hideType
        return NvEnum.uploadModule.material_animatedsticker
    } else if (materialType == NvNetwork.resourceTypeVideo) {
        return NvEnum.uploadModule.video
    } else if (materialType == NvNetwork.resourceTypeImage) {
        return NvEnum.uploadModule.image
    } else if (materialType == NvNetwork.resourceTypeAudio) {
        return NvEnum.uploadModule.audio
    } else if (materialType == NvNetwork.resourceTypeSplitVideoClip) {
        return NvEnum.uploadModule.split_video_clip
    } else {
        return NvEnum.uploadModule.material_unknown
    }
}

function extractPackageUUID(packageName) {
    let parts = packageName.split("\.")
    let name = parts[0]
    return name
}

function getFileExt(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase()
}

function hasMaterialVersion(fileName) {
    let parts = fileName.split("\.")
    return parts.length == 3
}

function getMaterialVersion(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 2]
    return name.toLowerCase()
}

function isImageFile(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "png" || name.toLowerCase() == "jpg" || name.toLowerCase() == "webp"
}

function isLicFile(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "lic"
}

function isRichTextFile(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "richtext"
}

function isHtmlFile(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "html"
}

function isJsonFile(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "json"
}

function isVideoFile(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "mp4"
}

function isZipFile(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "zip"
}

function isValidMaterial(fileName) {
    let parts = fileName.split("\.")
    let name = parts[parts.length - 1]
    return name.toLowerCase() == "theme" ||
        name.toLowerCase() == "videofx" ||
        name.toLowerCase() == "captionstyle" ||
        name.toLowerCase() == "captionanimation" ||
        name.toLowerCase() == "captioninanimation" ||
        name.toLowerCase() == "captionoutanimation" ||
        name.toLowerCase() == "captioncontext" ||
        name.toLowerCase() == "captionrenderer" ||
        name.toLowerCase() == "animatedsticker" ||
        name.toLowerCase() == "animatedstickerinanimation" ||
        name.toLowerCase() == "animatedstickeroutanimation" ||
        name.toLowerCase() == "animatedstickeranimation" ||
        name.toLowerCase() == "videotransition" ||
        name.toLowerCase() == "particle" ||
        name.toLowerCase() == "arscene" ||
        name.toLowerCase() == "compoundcaption" ||
        name.toLowerCase() == "template" ||
        name.toLowerCase() == "mp3" ||
        name.toLowerCase() == "m4a" ||
        name.toLowerCase() == "wav" ||
        name.toLowerCase() == "aac" ||
        name.toLowerCase() == "flac" ||
        name.toLowerCase() == "ttf" ||
        name.toLowerCase() == "otf" ||
        name.toLowerCase() == "ttc"
}

function isMaterialFile(fileName) {
    return isValidMaterial(fileName) ||
        isImageFile(fileName) ||
        isLicFile(fileName) ||
        isRichTextFile(fileName) ||
        isHtmlFile(fileName) ||
        isJsonFile(fileName) ||
        isVideoFile(fileName)
}

function getClass(o) { //判断数据类型
    return Object.prototype.toString.call(o).slice(8, -1);
}

function deepCopy(obj) {
    var result, oClass = getClass(obj);

    if (oClass == "Object") result = {}; //判断传入的如果是对象，继续遍历
    else if (oClass == "Array") result = []; //判断传入的如果是数组，继续遍历
    else return obj; //如果是基本数据类型就直接返回

    for (var i in obj) {
        var copy = obj[i];

        if (getClass(copy) == "Object") result[i] = deepCopy(copy); //递归方法 ，如果对象继续变量obj[i],下一级还是对象，就obj[i][i]
        else if (getClass(copy) == "Array") result[i] = deepCopy(copy); //递归方法 ，如果对象继续数组obj[i],下一级还是数组，就obj[i][i]
        else result[i] = copy; //基本数据类型则赋值给属性
    }

    return result;
}

function getTodayBeginString() {
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();

    return year + "-" + month + "-" + date + " " + "00:00:00"
}

function getTodayEndString() {
    let date_ob = new Date();
    let date = ("0" + date_ob.getDate()).slice(-2);
    let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
    let year = date_ob.getFullYear();

    return year + "-" + month + "-" + date + " " + "23:59:59"
}

function getExecuteResultCodeDisplayName(executeResultCode) {
    if (executeResultCode == NvNetwork.jobExecuteResultCodeSuccess) {
        return "job.result.jobExecuteResultCodeSuccess"
    } else if (executeResultCode == NvNetwork.jobExecuteResultCodeFail) {
        return "job.result.jobExecuteResultCodeFail"
    } else {
        return "job.result.jobExecuteResultCodeUnknown"
    }
}

function getExecuteStateDisplayName(executeState) {
    if (executeState == NvNetwork.jobExecuteStateFinish) {
        return "job.status.jobExecuteStateFinish"
    } else if (executeState == NvNetwork.jobExecuteStateWait) {
        return "job.status.jobExecuteStateWait"
    } else if (executeState == NvNetwork.jobExecuteStateWaitChild) {
        return "job.status.jobExecuteStateWaitChild"
    } else if (executeState == NvNetwork.jobExecuteStateWork) {
        return "job.status.jobExecuteStateWork"
    } else if (executeState == NvNetwork.jobExecuteStateCancel) {
        return "job.status.jobExecuteStateCancel"
    } else {
        return "job.status.jobExecuteStateUnknown"
    }
}

function getJobTypeDisplayName(jobType) {
    if (jobType == NvNetwork.jobTypeTranscode) {
        return "job.jobType.jobTypeTranscode"
    } else if (jobType == NvNetwork.jobTypeCompile) {
        return "job.jobType.jobTypeCompile"
    } else if (jobType == NvNetwork.jobTypeProjectTranscode) {
        return "job.jobType.jobTypeProjectTranscode"
    } else if (jobType == NvNetwork.jobTypeAudioCompare) {
        return "job.jobType.jobTypeAudioCompare"
    } else if (jobType == NvNetwork.jobTypeWordSplit) {
        return "job.jobType.jobTypeWordSplit"
    } else if (jobType == NvNetwork.jobTypeVideoSplit) {
        return "job.jobType.jobTypeVideoSplit"
    } else if (jobType == NvNetwork.jobTypeInstallMaterial) {
        return "job.jobType.jobTypeInstallMaterial"
    } else if (jobType == NvNetwork.jobTypeAITag) {
        return "job.jobType.jobTypeAITag"
    } else {
        return "job.jobType.jobTypeUnknown"
    }
}

function clearStorage() {
    localStorage.userToken = ""
    localStorage.email = ""
    localStorage.role = 0
    localStorage.menuIds = ""
}

function isMenuExist(menuId) {
    let ids = localStorage.menuIds.split(",")
    let exist = false
    ids.forEach(item => {
        if (menuId == parseInt(item)) {
            exist = true
        }
    })
    return exist
}

function extractFileName(keyPath) {
    let parts = keyPath.split('/')
    let name = parts[parts.length - 1]
    return name
}

function getLanguage () {
    return Cookies.get("language") || 'zh'
}

function setLanguage(language) {
    Cookies.set("language", language)
}
