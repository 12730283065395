<template>
  <el-main>
    <el-card>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item :label="$t('material.search.name')">
          <el-input v-model="formInline.keyword" class="material-uuid" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('material.search.id')">
          <el-input v-model="formInline.uuid" class="material-uuid" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item :label="$t('material.search.category')">
          <template>
            <el-cascader
                class="input-width"
                v-model="formInline.categoryCustom"
                clearable
                :placeholder="$t('material.search.select')"
                :options="customCategories">
            </el-cascader>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{ $t('material.search.search') }}</el-button>
          <template v-if="!isSmg()">
            <el-button type="primary" @click="onUpload" style="margin-left: 10px;">{{ $t('material.search.upload') }}
            </el-button>
          </template>
          <template v-else-if="isSmg()">
            <el-button type="primary" @click="onUpload" style="margin-left: 10px;">{{ $t('material.search.upload') }}
            </el-button>
            <el-button type="primary" style="position: absolute;left:-200px;top:-200px;" id="material-upload">
              {{ $t('material.search.upload') }}
            </el-button>
          </template>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :data="tableData" style="width: 100%" class="table-box" :empty-text="$t('header.dialog.emptyText')">
        <el-table-column prop="id" :label="$t('material.table.id')" align="center"
                         v-if="columnSetting.id"></el-table-column>
        <el-table-column prop="coverUrl" :label="$t('material.table.coverUrl')" align="center"
                         v-if="columnSetting.coverUrl">
          <template slot-scope="scope">
            <div style="display: flex;justify-content: center;">
              <el-image :src="scope.row.coverUrl" style="width: 50px;height: 50px;">
                <div slot="error" style="width: 100%;height: 100%;">
                  <img src="@/assets/default.png" style="width: 100%;height: 100%;"/>
                </div>
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="displayName" :label="$t('material.table.displayName')" align="center"
                         v-if="columnSetting.displayName && isLocaleEn()"></el-table-column>
        <el-table-column prop="displayNamezhCN" :label="$t('material.table.displayName')" align="center"
                         v-if="columnSetting.displayName && !isLocaleEn()"></el-table-column>
        <el-table-column prop="categoryCustomDisplayName" :label="$t('material.table.categoryCustomDisplayName')"
                         align="center"
                         v-if="columnSetting.categoryCustomDisplayName"></el-table-column>
        <el-table-column prop="kindDisplayName" :label="$t('material.table.kindDisplayName')" align="center"
                         v-if="columnSetting.kindDisplayName"></el-table-column>
        <el-table-column prop="supportedAspectRatio" :label="$t('material.table.supportedAspectRatio')" align="center"
                         width="150"
                         v-if="materialType != materialTypeMusic && materialType != materialTypeFont && columnSetting.supportedAspectRatio">
          <template slot-scope="scope" v-if="materialType != materialTypeMusic && materialType != materialTypeFont">
            <div class="tags">
              <el-tag size="small" v-if="(scope.row.supportedAspectRatio & 1) === 1">16:9</el-tag>
              <el-tag size="small" v-if="(scope.row.supportedAspectRatio & 2) === 2">1:1</el-tag>
              <el-tag size="small" v-if="(scope.row.supportedAspectRatio & 4) === 4">9:16</el-tag>
              <el-tag size="small" v-if="(scope.row.supportedAspectRatio & 8) === 8">4:3</el-tag>
              <el-tag size="small" v-if="(scope.row.supportedAspectRatio & 16) === 16">3:4</el-tag>
              <el-tag size="small" v-if="(scope.row.supportedAspectRatio & 32) === 32">18:9</el-tag>
              <el-tag size="small" v-if="(scope.row.supportedAspectRatio & 64) === 64">9:18</el-tag>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="lastUpdateTime" :label="$t('material.table.lastUpdateTime')" align="center"
                         v-if="columnSetting.lastUpdateTime"></el-table-column>
        <el-table-column prop="demoUrl" label="Demo" align="center" width="100"
                         v-if="materialType != materialTypeMusic && materialType != materialTypeFont && columnSetting.demoUrl">
          <template slot-scope="scope">
            <el-button @click="showDemo(scope.row)" size="small">{{ $t('material.table.search') }}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="statusDisplayName" :label="$t('material.table.statusDisplayName')" align="center"
                         v-if="columnSetting.statusDisplayName">
          <template slot-scope="scope" v-if="true">
            <el-row>{{ scope.row.statusDisplayName }}</el-row>
            <el-progress :percentage="scope.row.transcodingProgress" v-if="scope.row.transcodingProgressVisible"
                         :show-text='false'></el-progress>
          </template>
        </el-table-column>
        <el-table-column prop="licenseUrl" :label="$t('material.table.licenseUrl')" align="center"
                         v-if="materialType != materialTypeMusic && materialType != materialTypeFont && columnSetting.licenseUrl">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.licenseUrl" placement="top-start">
              <el-button size="small">{{ $t('material.table.link') }}</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="m3u8Url" :label="$t('material.table.m3u8Url')" align="center"
                         v-if="materialType == materialTypeMusic && columnSetting.m3u8Url">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" :content="scope.row.m3u8Url" placement="top-start">
              <el-button size="small">{{ $t('material.table.link') }}</el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="orderScore" :label="$t('material.table.orderScore')" align="center"
                         v-if="columnSetting.orderScore">
        </el-table-column>
        <el-table-column :prop="$t('material.table.operation')" :label="$t('material.table.operation')" width="200px;"
                         align="center">
          <template slot="header">
            <div style="display: flex;flex-direction: row;justify-content: center;align-items: center;">
              <span>{{ $t('material.table.operation') }}</span>
              <i class="el-icon-setting setting-icon" @click="modifySetting"></i>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="operation">
              <el-button @click="onClickRow(scope.row)" type="text" size="mini" width="130px;"
                         :disabled="scope.row.status == 0">
                {{ $t('material.table.edit') }}
              </el-button>
              <el-button @click="onDeleteMaterial(scope.row)" type="text" size="mini" width="130px;"
                         v-if="isDeletable()">
                {{ $t('material.table.delete') }}
              </el-button>
              <el-link size="small" style="font-size:12px;" type="primary" :href="scope.row.packageUrl" target="_blank">
                {{ $t('material.table.download') }}
              </el-link>
              <el-upload
                  class="upload-demo"
                  action=""
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :show-file-list="false"
                  :on-exceed="handleExceed"
                  :http-request="uploadFile"
                  :on-progress="onUploadProgress"
                  accept=".mp4"
                  :file-list="fileList">
                <el-button size="mini" type="text" @click="onBeforeUpload(scope.row)">
                  {{ $t('material.table.updateDemo') }}
                </el-button>
                <el-progress :percentage="scope.row.progress" v-if="scope.row.progressVisible"
                             :show-text='false'></el-progress>
              </el-upload>
              <el-button @click="replaceCover(scope.row)" type="text" size="mini">
                {{ $t('material.table.updateCover') }}
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog :visible.sync="demoDialogVisible" :destroy-on-close=true>
      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center">
        <video :src="demoUrl" style="width: 800px;height: 450px" controls></video>
      </div>
    </el-dialog>

    <el-card>
      <div class="pagination">
        <el-pagination
            :page-size="10  "
            :pager-count="11"
            layout="prev, pager, next"
            @size-change="getMaterialList"
            @current-change="getMaterialList"
            @prev-click="getMaterialList"
            @next-click="getMaterialList"
            :total="total">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :title="$t('material.dialog.modifyMaterial.title')" :visible.sync="dialogFormVisible">
      <el-form :model="modifyingRow">
        <el-form-item :label="$t('material.dialog.modifyMaterial.id')" :label-width="formLabelWidth">
          <el-input v-model="modifyingRow.id" autocomplete="off" style="width: 400px;float: left;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('material.dialog.modifyMaterial.name')" :label-width="formLabelWidth">
          <el-input v-model="modifyingRow.displayNamezhCN" autocomplete="off" v-if="!isLocaleEn()"
                    style="width: 400px;float: left;"></el-input>
          <el-input v-model="modifyingRow.displayName" autocomplete="off" v-if="isLocaleEn()"
                    style="width: 400px;float: left;"></el-input>
        </el-form-item>
        <el-form-item :label="$t('material.dialog.modifyMaterial.category')" :label-width="formLabelWidth">
          <el-cascader
              class="input-width"
              v-model="modifyingRow.categoryCustom"
              clearable
              :options="customCategories">
          </el-cascader>
        </el-form-item>
        <el-form-item :label="$t('material.dialog.modifyMaterial.status')" :label-width="formLabelWidth">
          <el-cascader
              class="input-width"
              v-model="modifyingRow.status"
              clearable
              :options="materialStatus">
          </el-cascader>
        </el-form-item>
        <el-form-item :label="$t('material.dialog.modifyMaterial.orderScore')" :label-width="formLabelWidth">
          <el-input v-model="modifyingRow.orderScore" autocomplete="off" style="width: 400px;float: left;"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false" style="margin-right: 20px;">
          {{ $t('material.dialog.modifyMaterial.cancel') }}
        </el-button>
        <el-button type="primary" @click="onUpdateMaterial()">{{
            $t('material.dialog.modifyMaterial.confirm')
          }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog
        :title="$t('material.dialog.deleteMaterial.title')"
        :visible.sync="deleteDialog"
        width="30%"
        center>
      <span>{{ $t('material.dialog.deleteMaterial.content') }}</span>
      <span slot="footer" class="dialog-footer">
                <el-button @click="deleteDialog = false"
                           style="margin-right: 20px;">{{ $t('material.dialog.deleteMaterial.cancel') }}</el-button>
                <el-button type="primary"
                           @click="onDeleteConfirm()">{{ $t('material.dialog.deleteMaterial.confirm') }}</el-button>
            </span>
    </el-dialog>

    <el-dialog :title="$t('material.dialog.uploadMaterial.upload')+ ' ' + $t(materialName)"
               v-if="uploadDialog"
               :visible.sync="uploadDialog"
               width="35%"
               :close-on-click-modal="false"
               center>
      <el-form :model="this.uploadingMaterialInfo">
        <el-form-item :label="$t(materialName)+ ' ' + $t('material.dialog.uploadMaterial.package')"
                      :label-width="formLabelWidth">
          <el-upload action="https://jsonplaceholder.typicode.com/posts/"
                     :limit="5"
                     :auto-upload=false
                     :show-file-list=false
                     :multiple=true
                     :accept="getAcceptSuffix()"
                     :on-change="onMaterialPackageFileChange">
            <el-button>{{ $t('material.dialog.uploadMaterial.upload') }}{{ $t(materialName) }}</el-button>
          </el-upload>

          <el-row v-for="(item) in this.uploadingMaterialInfo.uploadingMaterials" :key="item.uuid" :value="item.uuid"
                  style="margin-top: 20px;">
            <el-row style="display: flex;flex-direction: row;justify-content: space-between;align-items: center">
              <el-input disabled style="border: none; margin-right: 10px;" :value="item.packageFile.name"></el-input>
              <el-tag>{{
                  typeof item.statusDisplayName == 'undefined' ? $t('material.dialog.uploadMaterial.uploading') : item.statusDisplayName
                }}
              </el-tag>
            </el-row>
            <el-progress :percentage="item.progress" v-if="item.progressVisible"
                         style="margin-top: 5px;width: 100%;"
                         :show-text='false'></el-progress>
          </el-row>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: -40px;margin-bottom: 10px;">
        <el-button @click="uploadDialog = false">{{ $t('material.dialog.uploadMaterial.cancel') }}</el-button>
        <el-button type="primary" @click="onAddMaterial()" style="margin-left: 50px;"
                   :disabled=this.addMaterialButtonDisabled>{{ $t('material.dialog.uploadMaterial.confirm') }}
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="$t('material.dialog.updateCover.title')" width="30%" :visible.sync="replaceCoverDialogVisible"
               :destroy-on-close="true">
      <el-form :model="coverInfo" style="padding-bottom: 0px;">
        <el-form-item style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
          <el-upload
              class="upload-demo"
              drag
              action="https://jsonplaceholder.typicode.com/posts/"
              :auto-upload=false
              :on-change="onCoverFileChange"
              :limit="1">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">{{ $t('material.dialog.updateCover.content') }}</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button
              @click="replaceCoverDialogVisible = false">{{ $t('material.dialog.updateCover.cancel') }}</el-button>
          <el-button type="primary" @click="onConfirmCover()" style="margin-left: 20px;"
                     :disabled="addCoverButtonDisabled">{{ $t('material.dialog.updateCover.confirm') }}</el-button>
      </span>
    </el-dialog>

    <el-dialog :title="$t('material.dialog.columnSetting.title')" width="30%" :visible.sync="settingDialogVisible"
               :destroy-on-close="true">
      <el-row>
        <el-col :span="5">
          <el-checkbox v-model="tempColumnSetting.id">{{ $t('material.dialog.columnSetting.id') }}</el-checkbox>
        </el-col>
        <el-col :span="5">
          <el-checkbox v-model="tempColumnSetting.coverUrl">{{ $t('material.dialog.columnSetting.coverUrl') }}
          </el-checkbox>
        </el-col>
        <el-col :span="5">
          <el-checkbox v-model="tempColumnSetting.displayName">
            {{ $t('material.dialog.columnSetting.displayName') }}
          </el-checkbox>
        </el-col>
        <el-col :span="5">
          <el-checkbox v-model="tempColumnSetting.categoryCustomDisplayName">
            {{ $t('material.dialog.columnSetting.categoryCustomDisplayName') }}
          </el-checkbox>
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="tempColumnSetting.kindDisplayName">
            {{ $t('material.dialog.columnSetting.kindDisplayName') }}
          </el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <el-checkbox v-model="tempColumnSetting.supportedAspectRatio">
            {{ $t('material.dialog.columnSetting.supportedAspectRatio') }}
          </el-checkbox>
        </el-col>
        <el-col :span="5">
          <el-checkbox v-model="tempColumnSetting.lastUpdateTime">
            {{ $t('material.dialog.columnSetting.lastUpdateTime') }}
          </el-checkbox>
        </el-col>
        <el-col :span="5">
          <el-checkbox v-model="tempColumnSetting.demoUrl">{{ $t('material.dialog.columnSetting.demoUrl') }}
          </el-checkbox>
        </el-col>
        <el-col :span="5">
          <el-checkbox v-model="tempColumnSetting.statusDisplayName">
            {{ $t('material.dialog.columnSetting.statusDisplayName') }}
          </el-checkbox>
        </el-col>
        <el-col :span="4">
          <el-checkbox v-model="tempColumnSetting.orderScore">{{ $t('material.dialog.columnSetting.orderScore') }}
          </el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="5">
          <el-checkbox v-model="tempColumnSetting.licenseUrl">{{ $t('material.dialog.columnSetting.licenseUrl') }}
          </el-checkbox>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer">
          <el-button @click="settingDialogVisible = false">{{ $t('material.dialog.columnSetting.cancel') }}</el-button>
          <el-button type="primary" @click="onConfirmSetting()"
                     style="margin-left: 20px;">{{ $t('material.dialog.columnSetting.confirm') }}</el-button>
      </span>
    </el-dialog>
  </el-main>

</template>

<script>
import axios from "axios";
import NvNetwork from "../api/NvNetwork"
import NvUtils from "@/api/NvUtils";
import NvEnum from "@/api/NvEnum";
import JSZip from 'jszip';
import {getConfig, streamUpload} from "@/api/NvSmgUploader";
import {mapGetters} from "vuex";

export default {
  name: "NvMaterialTheme",
  computed: {
    ...mapGetters(['page'])
  },
  data() {
    return {
      userRole: 0,
      materialTypeMusic: NvNetwork.materialTypeMusic,
      materialTypeFont: NvNetwork.materialTypeFont,
      materialTypeBackgroundImage: NvNetwork.materialTypeBackgroundImage,
      materialTypeTemplate: NvNetwork.materialTypeTemplate,
      materialName: '',
      deleteDialog: false,
      uploadDialog: false,
      fileList: [],
      dialogFormVisible: false,
      formLabelWidth: '130px',
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 1000,
      formInline: {
        uuid: '',
        category: '',
        categoryCustom: '',
        isCustom: '',
        aspectRatio: '',
        startTime: '',
        endTime: '',
        keyword: '',
      },
      modifyingRow: [],       // todo 正在更新的行
      uploadingMaterialInfo: {
        uploadingMaterials: [],
      },
      addMaterialButtonDisabled: true,
      materialType: 1,
      materialSubType: 1,
      transcodingProgressTimer: '',
      categories: [],
      customCategories: [],
      materialStatus: NvNetwork.materialStatus,
      aspectRatios: [{
        label: "16:9",
        value: 1
      }, {
        label: "1:1",
        value: 2
      }, {
        label: "9:16",
        value: 4
      }, {
        label: "4:3",
        value: 8
      }, {
        label: "3:4",
        value: 16
      }, {
        label: "18:9",
        value: 32
      }, {
        label: "9:18",
        value: 64
      },
      ],
      checkedRatioGroup: [],
      aspectRatioFlag: [{
        label: "非通用",
        value: 0
      }, {
        label: "通用",
        value: 1
      }
      ],
      checkedRatioFlag: [],
      replaceCoverDialogVisible: false,
      coverInfo: {},
      addCoverButtonDisabled: true,
      demoDialogVisible: false,
      demoUrl: "",
      settingDialogVisible: false,
      columnSetting: {
        id: true,
        coverUrl: true,
        displayName: true,
        categoryCustomDisplayName: true,
        kindDisplayName: true,
        supportedAspectRatio: true,
        lastUpdateTime: true,
        demoUrl: true,
        statusDisplayName: true,
        licenseUrl: true,
        m3u8Url: true,
        orderScore: true
      },
      tempColumnSetting: {}
    }
  },
  inject: ['reload'],
  methods: {
    isLocaleEn() {
      return NvUtils.getLanguage() === 'en'
    },
    isSmg() {
      return getUploadType() == "smg"
    },
    getAcceptSuffix() {
      if (this.materialType == this.materialTypeMusic) {
        return '.mp3,.wav,.aac'
      } else if (this.materialType == this.materialTypeBackgroundImage) {
        return '.png,.jpg,.gif'
      } else {
        return '.zip,.richtext'
      }
    },
    modifySetting() {
      this.settingDialogVisible = true
      this.tempColumnSetting = NvUtils.deepCopy(this.columnSetting)
    },
    showDemo(row) {
      this.demoDialogVisible = true
      this.demoUrl = row.demoUrl
    },
    isDeletable() {
      return this.userRole == NvEnum.roleType.admin || this.userRole == NvEnum.roleType.innerUser
    },
    isUploadable() {
      return this.userRole == NvEnum.roleType.admin || this.userRole == NvEnum.roleType.bilibiliUser
    },
    onConfirmSetting() {
      this.settingDialogVisible = false
      this.columnSetting = NvUtils.deepCopy(this.tempColumnSetting)
    },
    onConfirmCover() {
      let network = new NvNetwork()
      network.updateMaterialCover(this.modifyingRow.id, this.coverInfo.coverUrl).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.getMaterialList(this.currentPage, this.pageSize);
          this.replaceCoverDialogVisible = false
          this.$message.success(this.$t('material.success.updateCoverSuccess'))
        } else {
          this.$message.error(this.$t('material.error.updateCoverFailed'))
        }
      }).catch(error => {
        this.$message.error(this.$t('material.error.updateCoverFailed'))
      })
    },

    onCoverFileChange(file, fileList) {
      let coverFile = new File([file.raw], file.name, {type: file.raw.type})
      console.info(file, coverFile)

      let network = new NvNetwork()
      network.upload(this.materialType, file.name, this.modifyingRow.id, coverFile, data => {
        if (!data.uploadFinish) {
          let progress = parseInt((data.progress).toFixed(0))
          console.log(this.$t('material.log.uploadProgress'), progress);
        } else {
          console.log(this.$t('material.log.materialLocation'), data.Location)
          let uuid = NvUtils.generateUUID()
          this.coverInfo.coverUrl = data.Location
          this.addCoverButtonDisabled = false
        }
      }, error => {
        console.log(error)
      }, true)
    },
    replaceCover(row) {
      this.modifyingRow = Object.assign({}, row);
      this.replaceCoverDialogVisible = true
    },
    onMaterialPackageFileChange(file, fileList) {
      fileList.forEach((fileItem, fileIndex) => {
        let exist = false
        this.uploadingMaterialInfo.uploadingMaterials.forEach(materialItem => {
          if ((this.materialType == this.materialTypeMusic && materialItem.displayName == fileItem.name) ||
              (this.materialType != this.materialTypeMusic && materialItem.uuid == NvUtils.extractPackageUUID(fileItem.name))
          ) {
            exist = true
          }
        })
        if (exist) {
          return false
        }

        let uploadingMaterial = []

        uploadingMaterial.packageFile = new File([fileItem.raw], fileItem.name, {type: fileItem.raw.type})
        let uuid = NvUtils.extractPackageUUID(fileItem.name)
        uploadingMaterial.uuid = uuid
        if (this.materialType == this.materialTypeMusic ||
            this.materialType == this.materialTypeBackgroundImage
        ) {
          uuid = NvUtils.generateUUID()
          uploadingMaterial.uuid = uuid
          uploadingMaterial.displayNameZhCN = fileItem.name
          uploadingMaterial.displayName = fileItem.name
        } else if (this.materialType == this.materialTypeFont) {
          uploadingMaterial.displayNameZhCN = uuid
          uploadingMaterial.displayName = uuid
          uuid = NvUtils.generateUUID()
          uploadingMaterial.uuid = uuid
        }

        this.uploadingMaterialInfo.uploadingMaterials.push(uploadingMaterial)

        let network = new NvNetwork()
        if (this.materialType == this.materialTypeMusic ||
            this.materialType == this.materialTypeBackgroundImage ||
            (this.materialType == this.materialTypeTemplate && uploadZipTemplate())
        ) {
          // 如果上传音效，需要走服务器端的上传回调
          network.upload(this.materialType, fileItem.name, uuid, uploadingMaterial.packageFile, data => {
            if (!data.uploadFinish) {
              uploadingMaterial.progress = parseInt((data.progress).toFixed(0))
              uploadingMaterial.progressVisible = true
              uploadingMaterial.statusDisplayName = this.$t('material.dialog.uploadMaterial.uploading')
            } else {
              uploadingMaterial.progress = 100
              uploadingMaterial.packageUrl = data.Location
              uploadingMaterial.statusDisplayName = this.$t('material.dialog.uploadMaterial.uploaded')
            }
            this.$set(this.uploadingMaterialInfo.uploadingMaterials, fileIndex, uploadingMaterial)

            this.addMaterialButtonDisabled = false
            this.uploadingMaterialInfo.uploadingMaterials.forEach(materialItem => {
              if (NvUtils.isEmpty(materialItem.packageUrl)) {
                this.addMaterialButtonDisabled = true
              }
            })
          }, error => {
            console.log(error)
          })
        } else {
          let isRichText = NvUtils.isRichTextFile(fileItem.name)
          uploadingMaterial.isRichText = isRichText
          JSZip.loadAsync(uploadingMaterial.packageFile).then((zip) => {
            let count = 0
            Object.keys(zip.files).forEach(item => {
              if (NvUtils.isMaterialFile(item)) {
                count++
              }
            })
            let uploadedCount = 0
            zip.forEach((path, zipItem) => {
              uploadingMaterial.statusDisplayName = this.$t('material.dialog.uploadMaterial.uploading')
              let file = zip.file(zipItem.name)
              if (file == null) {
                return false
              }
              zip.file(zipItem.name).async("blob").then((data) => {
                let isCoverOrDemoVideo = NvUtils.isImageFile(zipItem.name) || NvUtils.isVideoFile(zipItem.name) || NvUtils.isJsonFile(zipItem.name) || NvUtils.isHtmlFile(zipItem.name)
                network.upload(this.materialType, zipItem.name, uuid, data, response => {
                  console.log(response)

                  uploadingMaterial.progress = parseInt(response.progress)
                  uploadingMaterial.progressVisible = true
                  uploadingMaterial.statusDisplayName = this.$t('material.dialog.uploadMaterial.uploading')

                  if (isRichText) {
                    if (NvUtils.isImageFile(zipItem.name)) {
                      uploadingMaterial.richTextImageUrl = response.Location
                    } else if (NvUtils.isHtmlFile(zipItem.name)) {
                      uploadingMaterial.richTextHtmlUrl = response.Location
                    } else if (NvUtils.isJsonFile(zipItem.name)) {
                      uploadingMaterial.richTextInfoJsonUrl = response.Location
                      zip.file(zipItem.name).async("string").then(jsonData => {
                        let info = JSON.parse(jsonData)
                        uploadingMaterial.uuid = info.uuid
                        uploadingMaterial.originUuid = info.originUuid
                      })
                    }
                  } else {
                    if (NvUtils.isValidMaterial(zipItem.name)) {
                      uploadingMaterial.packageUrl = response.Location
                      uploadingMaterial.uuid = uuid
                    } else if (NvUtils.isImageFile(zipItem.name)) {
                      uploadingMaterial.coverUrl = response.Location
                      uploadingMaterial.uuid = uuid
                    } else if (NvUtils.isLicFile(zipItem.name)) {
                      uploadingMaterial.licenseUrl = response.Location
                      uploadingMaterial.uuid = uuid
                    } else if (NvUtils.isVideoFile(zipItem.name)) {
                      uploadingMaterial.demoUrl = response.Location
                      uploadingMaterial.uuid = uuid
                    } else if (NvUtils.isJsonFile(zipItem.name)) {
                      if (zipItem.name == "info.json") {
                        uploadingMaterial.infoUrl = response.Location
                        zip.file(zipItem.name).async("string").then(jsonData => {
                          let info = JSON.parse(jsonData)
                          uploadingMaterial.uuid = info.uuid
                          uploadingMaterial.materialDuration = info.duration
                          if (!NvUtils.isEmpty(info.translation) && info.translation.length > 0) {
                            uploadingMaterial.displayName = info.translation[0].originalText
                            uploadingMaterial.displayNameZhCN = info.translation[0].targetText
                          }
                          uploadingMaterial.supportedAspectRatio = this.convertSupportedAspectRatio(info.supportedAspectRatio)
                        })
                      }
                    } else if (NvUtils.isTemplateFont(zipItem.name)) {
                      if (NvUtils.isEmpty(uploadingMaterial.fontUrls)) {
                        uploadingMaterial.fontUrls = []
                      }
                      uploadingMaterial.fontUrls.push(response.Location)
                    } else if (NvUtils.isTemplateAsset(zipItem.name)) {
                      if (NvUtils.isEmpty(uploadingMaterial.assetUrls)) {
                        uploadingMaterial.assetUrls = []
                      }
                      uploadingMaterial.assetUrls.push(response.Location)
                    }
                  }

                  this.$set(this.uploadingMaterialInfo.uploadingMaterials, fileIndex, uploadingMaterial)

                  if (response.uploadFinish) {
                    uploadingMaterial.statusDisplayName = this.$t('material.dialog.uploadMaterial.uploaded')
                    uploadedCount++
                    if (uploadedCount == count) {
                      this.addMaterialButtonDisabled = false
                    }
                  }
                }, error => {
                  console.log(error)
                }, isCoverOrDemoVideo)
              })
            })
          })
        }
      })
    },
    convertSupportedAspectRatio(supportedAspectRatio) {
      let ratio = 0
      if (supportedAspectRatio.indexOf("16v9") >= 0) {
        ratio = ratio | 1
      }
      if (supportedAspectRatio.indexOf("1v1") >= 0) {
        ratio = ratio | 2
      }
      if (supportedAspectRatio.indexOf("9v16") >= 0) {
        ratio = ratio | 4
      }
      if (supportedAspectRatio.indexOf("4v3") >= 0) {
        ratio = ratio | 8
      }
      if (supportedAspectRatio.indexOf("3v4") >= 0) {
        ratio = ratio | 16
      }
      if (supportedAspectRatio.indexOf("18v9") >= 0) {
        ratio = ratio | 32
      }
      if (supportedAspectRatio.indexOf("9v18") >= 0) {
        ratio = ratio | 64
      }
      return ratio
    },
    onDeleteMaterial(row) {
      console.log(row)
      this.deleteDialog = true
      this.modifyingRow = Object.assign({}, row);
    },
    onDeleteConfirm() {
      this.deleteDialog = false

      let status = 100 // 删除标志
      this.deleteMaterial(this.modifyingRow.id)
    },
    /**
     * 上传素材demo
     */
    uploadFile(params) {
      let file = params.file
      if (file.name == undefined) {
        return
      }

      let ext = NvUtils.getFileExt(file.name)
      let key = NvUtils.getMaterialPackageKey(this.materialType) + this.modifyingRow.id + "/" + this.modifyingRow.id + "." + ext

      let itemInfo = {id: this.modifyingRow.id, progress: 0, key: key, progressVisible: true}
      this.updateUploadInfo(itemInfo);


      let network = new NvNetwork()
      network.upload(this.materialType, file.name, this.modifyingRow.id, file, responseData => {
        console.log(responseData)

        if (responseData.uploadFinish) {
          // todo 上传完成更新Url
          let demoUrl = responseData.Location
          let index = this.getUploadInfoIndexByKey(key)
          if (index >= 0) {
            let uploadInfo = this.tableData[index]
            uploadInfo.progressVisible = false
            uploadInfo.demoUrl = demoUrl
            this.updateUploadInfo(uploadInfo)
            network.updateDemo(uploadInfo.id, demoUrl).then(response => {
              if (response.code == NvNetwork.responseCodeSuccess) {
                this.$message.success(this.$t('material.success.updateDemoSuccess'))
              } else {
                this.$message.error(this.$t('material.error.updateDemoFailed'))
              }
            }).catch(error => {
              this.$message.error(this.$t('material.error.updateDemoFailed'))
            })
          }
        } else {
          let progress = responseData.progress
          let index = this.getUploadInfoIndexByKey(key)
          console.log(this.$t('material.log.uploadDemoProgress1'), progress)
          if (index >= 0) {
            console.log(this.$t('material.log.uploadDemoProgress2'), progress)
            let uploadInfo = this.tableData[index]
            uploadInfo.progress = progress
            uploadInfo.progressVisible = true
            this.updateUploadInfo(uploadInfo)
          }
        }
      }, null, true)
    },

    /**
     * 更新上传demo的进度信息
     */
    updateUploadInfo(itemInfo) {
      let index = this.getUploadInfoIndexById(itemInfo.id);
      if (index < 0) {
        console.log("Failed to find id " + itemInfo.id)
      } else {
        if (itemInfo.progress != undefined) {
          this.tableData[index].progress = itemInfo.progress
        }
        if (itemInfo.progressVisible != undefined) {
          this.tableData[index].progressVisible = itemInfo.progressVisible
        }
        if (itemInfo.key != undefined) {
          this.tableData[index].key = itemInfo.key
        }
        if (itemInfo.demoUrl != undefined) {
          this.tableData[index].demoUrl = itemInfo.demoUrl
        }
      }
      console.log("updateUploadInfo ")
      console.log(JSON.stringify(this.tableData[index]))
    },
    /**
     * 根据素材ID获取demo上传信息索引，-1表示没找到
     */
    getUploadInfoIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.tableData.length; i++) {
        if (id == this.tableData[i].id) {
          return i;
        }
      }
      return index;
    },
    /**
     * 根据素材key获取demo上传信息索引，-1表示没找到
     */
    getUploadInfoIndexByKey(key) {
      let index = -1;
      for (let i = 0; i < this.tableData.length; i++) {
        if (key == this.tableData[i].key) {
          return i;
        }
      }
      return index;
    },

    onBeforeUpload(row) {
      console.log(row)
      this.modifyingRow = Object.assign({}, row);
    },
    onUploadProgress(ev) {
      console.log(ev.progress)
    },
    /**
     * 上传demo的响应函数
     * @param file
     * @param fileList
     */
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(this.$t('material.warning.updateDemoWarning'));
    },
    beforeRemove(file, fileList) {
      console.log(file, fileList);
      return this.$confirm(this.$t('material.info.removeDemo'));
    },
    /**
     * 点击更新素材
     */
    onClickRow(row) {
      this.modifyingRow = Object.assign({}, row);
      this.dialogFormVisible = true
    },
    getUploadCategory() {
      return (this.materialSubType - 1) * 100
    },
    onAddMaterial() {

      this.uploadingMaterialInfo.uploadingMaterials.forEach(materialItem => {
        if (!materialItem.isRichText) {
          if (!NvUtils.isValidMaterial(materialItem.packageUrl) && !NvUtils.isImageFile(materialItem.packageUrl) && !NvUtils.isZipFile(materialItem.packageUrl)) {
            this.$message.warning(this.$t('material.warning.materialTypeWarning'))
            return false
          }
          if (NvUtils.isEmpty(materialItem.uuid) || NvUtils.isEmpty(materialItem.packageUrl)) {
            console.log(this.$t('material.log.materialInfoError'))
            return false
          }
        }

        if (materialItem.isRichText) {
          let network = new NvNetwork()
          network.addRichText(materialItem.originUuid, materialItem.uuid, materialItem.richTextImageUrl, materialItem.richTextHtmlUrl, materialItem.richTextInfoJsonUrl).then(response => {
            console.log(response)
            if (response.code == NvNetwork.responseCodeSuccess) {
              this.getMaterialList(this.currentPage, this.pageSize);
            } else {
              this.$message.warning(response.message)
            }
          }).catch(error => {
            console.log(error)
          })
        } else {
          let network = new NvNetwork()
          network.addMaterial(this.materialType,
              materialItem.uuid,
              materialItem.packageUrl,
              materialItem.coverUrl,
              materialItem.displayName,
              materialItem.displayNameZhCN,
              materialItem.ratio,
              materialItem.ratioFlag,
              materialItem.licenseUrl,
              0,
              this.getUploadCategory(),
              materialItem.demoUrl,
              materialItem.materialDuration,
              materialItem.supportedAspectRatio,
              materialItem.infoUrl,
              materialItem.fontUrls,
              materialItem.assetUrls
          ).then(response => {
            console.log(response)
            if (response.code == NvNetwork.responseCodeSuccess) {
              this.getMaterialList(this.currentPage, this.pageSize);
            } else {
              this.$message.warning(response.message)
            }
          }).catch(error => {
            console.log(error)
          })
        }
      })

      this.uploadDialog = false
    },
    /**
     * 更新素材分类
     */
    onUpdateMaterial() {
      if (this.modifyingRow.categoryCustom === "" && this.materialType != NvNetwork.materialTypeFont) {
        this.$message.error(this.$t('material.error.categoryIsNull'));
        return;
      }

      let categoryId = NvUtils.isEmpty(this.modifyingRow.categoryCustom) ? 0 : this.modifyingRow.categoryCustom[0]
      let kindId = (!NvUtils.isEmpty(this.modifyingRow.categoryCustom) && this.modifyingRow.categoryCustom.length > 1) ? this.modifyingRow.categoryCustom[1] : null
      let orderScore = this.modifyingRow.orderScore

      let id = (kindId == null ? categoryId : kindId)
      this.updateMaterial(this.modifyingRow.id, id, null, this.modifyingRow.status, this.modifyingRow.displayName, this.modifyingRow.displayNamezhCN, null, null, orderScore).then(response => {
        if (response.success) {
          this.getMaterialList(this.currentPage, this.pageSize);
          this.dialogFormVisible = false
          this.$message.success(this.$t('material.success.updateMaterialSuccess'))
        } else {
          this.$message.error(this.$t('material.error.updateMaterialFailed'))
        }
      }).catch(error => {
        this.$message.error(this.$t('material.error.updateMaterialFailed'))
      })
    },
    /**
     * 查询素材
     */
    onSubmit() {
      if (this.formInline.category != '' && this.formInline.categoryCustom != '') {
        this.$message.error(this.$t('material.error.searchCategoryFailed'));
        return
      }
      if (this.formInline.category != '') {
        this.formInline.isCustom = 0;
      }
      if (this.formInline.categoryCustom != '') {
        this.formInline.isCustom = 1;
      }
      this.currentPage = 1;
      this.getMaterialList(this.currentPage, this.pageSize);
    },
    onSync() {
      let network = new NvNetwork()
      network.syncMaterial(this.materialType).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.getMaterialList(this.currentPage, this.pageSize)
        } else {
          this.$message.warning(response.message)
        }
      }).catch(error => {
        console.log(error)
      })
    },
    onUpload() {
      this.uploadingMaterialInfo.uploadingMaterials.length = 0
      this.addMaterialButtonDisabled = true
      this.uploadDialog = true
    },
    async updateMaterial(id, categoryCustom, demoUrl, status, displayName, displayNamezhCN, coverUrl, kindId, orderScore) {
      let network = new NvNetwork()
      let response = await network.updateMaterial(id, categoryCustom, demoUrl, status, displayName, displayNamezhCN, coverUrl, kindId, orderScore)

      return new Promise((resolve, reject) => {
        let result = {
          success: response.code == NvNetwork.responseCodeSuccess
        }
        resolve(result)
      })
    },
    deleteMaterial(id) {
      let network = new NvNetwork()
      network.deleteMaterial(id).then(response => {
        console.log(response)
        this.getMaterialList(this.currentPage, this.pageSize);
      }).catch(error => {
        console.log(error)
      })
    },
    getMaterialList(pageNum, pageSize) {
      if (NvUtils.isEmpty(pageNum)) {
        pageNum = 1
      }
      if (NvUtils.isEmpty(pageSize)) {
        pageSize = 10
      }
      this.currentPage = pageNum
      let {uuid, category, categoryCustom, isCustom, aspectRatio, startTime, endTime, keyword} = this.formInline
      if (uuid || category || categoryCustom || isCustom || aspectRatio || startTime || endTime || keyword) this.currentPage = 1

      if (isCustom) {
        category = categoryCustom
      }
      let categoryId = category[0]
      let kindId = NvUtils.isEmpty(category[1]) ? null : category[1]

      let network = new NvNetwork()
      network.getMaterialList(pageNum, pageSize, this.materialType, uuid, categoryId, isCustom, aspectRatio, startTime, endTime, this.materialSubType, keyword, kindId).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          // todo 绑定数据的时候一定要一次绑定好，不然会绑定失败
          if (response.data.materialCount > 0) {
            let materialList = response.data.materialList
            materialList.forEach(item => {
              item.progress = 0;
              item.progressVisible = false;
            })
            this.tableData = materialList
            this.resetMaterialStatus()
            this.total = response.data.materialCount

            this.setupCategoryDisplayName()
          } else {
            this.tableData = []
            this.total = 0
          }
        } else {
          this.$message.error(this.$t('material.error.getMaterialListFailed'))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    setupCategoryDisplayName() {
      this.tableData.forEach(item => {
        this.categories.forEach(category => {
          if (category.id == item["category"]) {
            item["categoryDisplayName"] = this.isLocaleEn() ? category["displayName"] : category["displayNamezhCN"]
          }
        })
        if (!NvUtils.isEmpty(this.customCategories)) {
          this.customCategories.forEach(category => {
            if (category.id == item["categoryCustom"]) {
              item["categoryCustomDisplayName"] = this.isLocaleEn() ? category["displayName"] : category["displayNamezhCN"]
            } else {
              if (!NvUtils.isEmpty(category.children)) {
                category.children.forEach(kind => {
                  if (kind.id == item["categoryCustom"]) {
                    item["kindDisplayName"] = this.isLocaleEn() ? kind["displayName"] : kind["displayNamezhCN"]
                    item["categoryCustomDisplayName"] = this.isLocaleEn() ? category["displayName"] : category["displayNamezhCN"]
                  }
                })
              }
            }
          })
        }
      })
    },
    resetMaterialStatus() {
      this.tableData.forEach(materialItem => {
        if (materialItem.status == 100) {
          materialItem.statusDisplayName = this.$t('material.status.deleted')
        } else if (materialItem.status == 0) {
          materialItem.statusDisplayName = this.$t('material.status.init')
          if (materialItem.transcodingProgressVisible) {
            if (this.materialType == NvNetwork.materialTypeMusic) {
              materialItem.statusDisplayName = this.$t('material.status.transcoding')
            } else {
              materialItem.statusDisplayName = this.$t('material.status.installing')
            }
          }
        } else if (materialItem.status == 1) {
          materialItem.statusDisplayName = this.$t('material.status.normal')
        } else if (materialItem.status == 2) {
          materialItem.statusDisplayName = this.$t('material.status.transcodeFailed')
        } else if (materialItem.status == 3) {
          materialItem.statusDisplayName = this.$t('material.status.notDisplay')
        }
      })
    },
    async getMaterialCategories(isCustom) {
      let network = new NvNetwork()
      let response = await network.getMaterialCategories(this.materialType, isCustom)
      if (response.code == NvNetwork.responseCodeSuccess) {
        if (NvUtils.isEmpty(response.data)) {
          return
        }
        if (isCustom == 0) {
          this.categories = response.data.materialCategories[0].children
        } else {
          this.customCategories = response.data.materialCategories[0].children
          if (!NvUtils.isEmpty(this.customCategories)) {
            this.customCategories.forEach(item => {
              item.value = item.id
              item.label = this.isLocaleEn() ? item.displayName : item.displayNamezhCN
              if (!NvUtils.isEmpty(item.children)) {
                item.children.forEach(grandChild => {
                  grandChild.value = grandChild.id
                  grandChild.label = this.isLocaleEn() ? grandChild.displayName : grandChild.displayNamezhCN
                })
              }
            })
          }
        }
      } else {
        this.$message.error(this.$t('material.error.getCategoryFailed'))
      }

      return new Promise((resolve, reject) => {
        let message = "success"
        resolve(message)
      })
    },
    updateTranscodingProgress() {
      this.tableData.forEach((item, index) => {
        let jobExist = parseInt(item.status) == 0 && parseInt(item.jobId) > 0
        if (jobExist) {
          let network = new NvNetwork()
          network.getJobInfo(item.jobId).then(data => {
            console.log(data)
            item.status = data.status
            item.transcodingProgress = data.progress
            if (item.status == 1) {
              item.transcodingProgressVisible = false
            } else {
              item.transcodingProgressVisible = true
            }
            item.m3u8Url = data.m3u8Url
            this.$set(this.tableData, index, item)
            this.resetMaterialStatus()
          }).catch(error => {
            console.log(error)
          })
        }
      })
    }
  },
  async mounted() {
    this.materialType = this.$route.query.type
    if (!NvUtils.isEmpty(this.$route.query.subType)) {
      this.materialSubType = this.$route.query.subType
    }
    await this.getMaterialCategories(1);

    this.currentPage = this.page
    this.getMaterialList(this.currentPage, 10);
    this.materialName = NvUtils.getMaterialName(this.materialType)

    // 修改面包屑名称
    let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
    matched[0].meta.title = this.materialName

    // 初始化
    this.transcodingProgressTimer = setInterval(this.updateTranscodingProgress, 3000)
    this.userRole = localStorage.role

    if (getUploadType() == "smg") {
      getConfig()
    }
  },
  destroyed() {
    // 销毁监听
    clearInterval(this.transcodingProgressTimer)
  }
}
</script>

<style scoped lang="scss">
.material-uuid, .material-category, .material-aspect-ratio {
  width: 200px;
}

.start-time, .end-time {
  width: 150px;
}

.el-progress {
  width: 100px;
}

.operation {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.el-button + .el-button {
  margin-left: 0px;
}

.pagination {
  text-align: center;
}

.tags {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
  padding: 5px 0px;
}

::v-deep .el-table {
  font-size: 13px;

  th {
    padding: 0 !important;
    height: 48px;
    line-height: 48px;
  }

  td {
    padding: 0 !important;
    height: 48px;
    line-height: 48px;
  }
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}

::v-deep .el-dialog .el-form-item {
  margin-bottom: 10px;
}

.setting-icon {
  cursor: pointer;
  margin-left: 20px;
  transform: scale(1.5, 1.5)
}

.el-card {
  margin-bottom: 20px;
}
</style>
