<template>
  <el-header>
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />
    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" />
<!--    <img src="/image/logo-home.png" alt="" style="width:250px; height:65px;margin-left: -50px;">-->
    <el-row type="flex" justify="end" align="middle">
      <el-button type="primary" size="small" style="margin-right: 20px;" @click="onCategorySettingClicked" v-if="show_categorySetting">
        {{ $t("header.dialog.categorySetting") }}
      </el-button>
      <el-dropdown>
          <span class="el-dropdown-link">
            {{ email }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
        <el-dropdown-menu slot="dropdown">
          <el-button @click="onExit()" style="border: 0px;">
            {{ $t("header.exit") }}
          </el-button>
        </el-dropdown-menu>
      </el-dropdown>
    </el-row>
    <el-dialog :title="$t('header.dialog.categorySetting')"
               :visible.sync="categoryDialogVisible"
               :close-on-click-modal="false"
               width="30%">
      <div class="block">
        <el-tree
            :empty-text="$t('header.dialog.emptyText')"
            class="scroll-tree"
            :data="categoryData"
            node-key="id"
            default-expand-all
            :expand-on-click-node="false"
            :render-content="renderContent"
            @node-drag-start="handleDragStart"
            @node-drag-enter="handleDragEnter"
            @node-drag-leave="handleDragLeave"
            @node-drag-over="handleDragOver"
            @node-drag-end="handleDragEnd"
            @node-drop="handleDrop"
            draggable
            :allow-drop="allowDrop"
            :allow-drag="allowDrag">
        </el-tree>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="categoryDialogVisible = false">{{ $t("header.dialog.cancel") }}</el-button>
        <el-button type="primary" @click="categoryDialogVisible = false">{{ $t("header.dialog.confirm") }}</el-button>
      </span>
    </el-dialog>
  </el-header>
</template>

<script>

import NvUtils from "@/api/NvUtils";
import NvNetwork from "@/api/NvNetwork";

import { mapGetters } from 'vuex'
import Hamburger from '@/components/Hamburger'
import Breadcrumb from '@/components/Breadcrumb'

export default {
  name: "NvHeader",
  data() {
    return {
      categoryDialogVisible: false,
      newCategoryDialogVisible: false,
      categoryData: [],
      categoryDataBackup: [],
      materialTypes: NvNetwork.materialTypes,
      show_categorySetting: true
    }
  },
  created() {
    this.show_categorySetting = NvUtils.isMenuExist(NvNetwork.buttonId_categorySetting)
  },
  props: ['email'],
  components: {
    Breadcrumb,
    Hamburger,
  },
  computed: {
    ...mapGetters([
      'sidebar'
    ])
  },
  methods: {
    onExit() {
      delete localStorage.userToken
      delete localStorage.role
      delete localStorage.email
      this.$router.push('/login')
    },
    onCategorySettingClicked() {
      this.getCategoryData()
      this.categoryDialogVisible = true
    },
    setCategoryData(categoryData) {
      categoryData.forEach(item => {
        let typeChildren = item.children
        if (!NvUtils.isEmpty(typeChildren)) {
          typeChildren.forEach(child => {
            child.label = NvUtils.getLanguage() === 'zh' ? child.displayNamezhCN : child.displayName
            child.isShow = false
            child.materialType = item.id
            child.isCustom = 1
            child.parentType = child.parentType
            child.visibility = child.visibility
            if (!NvUtils.isEmpty(child.children)) {
              child.children.forEach(grandChild => {
                grandChild.label = NvUtils.getLanguage() === 'zh' ? grandChild.displayNamezhCN : grandChild.displayName
                grandChild.isShow = false
                grandChild.materialType = item.id
                grandChild.isCustom = 1
                grandChild.parentType = grandChild.parentType
                grandChild.visibility = grandChild.visibility
              })
            }
          })
          let data = {
            id: item.id,
            label: NvUtils.getLanguage() === 'zh' ? item.displayNamezhCN : item.displayName,
            children: typeChildren,
            isShow: false,
            materialType: item.id,
            level: NvNetwork.categoryLevel1,
            parentType: item.parentType,
            visibility: item.visibility
          }
          this.categoryData.push(data)
        }
      })
      this.categoryDataBackup = NvUtils.deepCopy(this.categoryData)
    },
    updateCategoryVisibility(categoryId, visibility) {
      this.categoryData.forEach((item, index) => {
        if (item.id == categoryId) {
          item.visibility = visibility
        }
        let typeChildren = item.children
        if (!NvUtils.isEmpty(typeChildren)) {
          typeChildren.forEach(child => {
            if (child.id == categoryId) {
              child.visibility = visibility
            }
            if (!NvUtils.isEmpty(child.children)) {
              child.children.forEach(grandChild => {
                if (grandChild.id == categoryId) {
                  grandChild.visibility = visibility
                }
              })
            }
          })
        }
      })
    },
    editMaterialCategory(categoryData) {
      let network = new NvNetwork()
      network.editMaterialCategory(categoryData.id, categoryData.materialType, categoryData.isCustom,
          categoryData.displayNamezhCN, categoryData.displayName, categoryData.level, categoryData.visibility).then(response => {
        if (response.code == 0) {
          this.updateCategoryVisibility(categoryData.id, categoryData.visibility)
          if (parseInt(categoryData.visibility) === 1) {
            document.getElementById(categoryData.id).innerHTML = '<span>' + this.$t("header.button.hide") + '</span>'
          } else {
            document.getElementById(categoryData.id).innerHTML = '<span>' + this.$t("header.button.show") + '</span>'
          }
          this.$message.success(this.$t('header.success.editCategorySuccess'))
        } else {
          this.$message.error(this.$t('header.error.editCategoryFailed'))
        }
      }).catch(error => {
        this.$message.error(this.$t('header.error.editCategoryFailed'))
      })
    },
    async addMaterialCategory(categoryData) {
      let network = new NvNetwork()
      let response = await network.addMaterialCategory(categoryData.id, categoryData.materialType, categoryData.isCustom, categoryData.displayNamezhCN, categoryData.displayName, categoryData.level, categoryData.parentType)

      if (response.code != 0) {
        this.$message.error(this.$t('header.error.addCategoryFailed'))
      }
      return new Promise((resolve) => {
        let result = {
          success: response.code == 0,
          id: response.data.id
        }
        resolve(result)
      })
    },
    async deleteMaterialCategory(categoryData) {
      let network = new NvNetwork()
      let response = await network.deleteMaterialCategory(categoryData.id, categoryData.materialType, categoryData.isCustom, categoryData.level)

      if (response.code != 0) {
        this.$message.error(this.$t('header.error.deleteCategoryFailed'))
      }

      return new Promise((resolve) => {
        let result = {
          success: response.code == 0
        }
        resolve(result)
      })
    },
    async moveMaterialCategory(categoryData, order) {
      let network = new NvNetwork()
      let response = await network.moveMaterialCategory(categoryData.id, categoryData.materialType, categoryData.isCustom, categoryData.level, order, categoryData.parentType)

      if (response.code != 0) {
        this.$message.error(this.$t('header.error.moveCategoryFailed'))
      }
      return response.code == 0
    },
    async getCategoryData() {
      this.categoryData = []
      let network = new NvNetwork()
      let response = await network.getAllMaterialCategory()
      if (response.code == 0) {
        this.setCategoryData(response.data)
      } else {
        this.$message.error(this.$t('header.error.getCategoryFailed'))
      }

      return new Promise((resolve) => {
        let result = {
          code: response.code == 0 ? 0 : 1
        }
        resolve(result)
      })
    },
    async append(data) {
      this.$prompt(this.$t('header.prompt.categoryNameInput'), this.$t('header.prompt.hint'), {
        confirmButtonText: this.$t('header.prompt.confirm'),
        cancelButtonText: this.$t('header.prompt.cancel'),
      }).then(({value}) => {
        let id = data.id
        if (data.level == NvNetwork.categoryLevel1) {
          let maxId = 0
          data.children.forEach(item => {
            maxId = maxId > item.id ? maxId : item.id
          })
          id = maxId
        }
        const newChild = {
          id: id,
          label: value,
          children: [],
          isShow: false,
          materialType: data.materialType,
          isCustom: 1,
          displayNamezhCN: value,
          displayName: value,
          level: data.level + 1,
          parentType: data.id
        };
        this.addMaterialCategory(newChild).then(response => {
          if (response.success) {
            if (!data.children) {
              this.$set(data, 'children', []);
            }
            newChild.id = response.id
            data.children.push(newChild);

            this.getCategoryData().then(response2 => {
              if (response2.code == 0) {
                console.log(this.$t('header.log.getCategorySuccess'))
              } else {
                console.log(this.$t('header.log.getCategoryFailed'))
              }
            }).catch(error => {
              console.log(this.$t('header.log.getCategoryFailed'))
            })
          } else {
            this.$message.error(this.$t('header.error.addCategoryFailed'))
          }
        }).catch(error => {
          this.$message.error(this.$t('header.error.addCategoryFailed'))
        })
      }).catch(() => {
        this.$message({type: 'info', message: this.$t('header.prompt.cancel')});
      });
    },
    remove(node, data) {
      this.$msgbox(this.$t('header.msgbox.deleteCategory'), this.$t('header.msgbox.hint'), {
        confirmButtonText: this.$t('header.msgbox.confirm'),
        cancelButtonText: this.$t('header.msgbox.cancel'),
      }).then(({value}) => {
        console.log('remove ', data, node)
        let deleteData = {
          id: data.id,
          materialType: data.materialType,
          level: data.level,
          isCustom: data.isCustom
        }
        this.deleteMaterialCategory(deleteData).then(response => {
          if (response.success) {
            const parent = node.parent;
            const children = parent.data.children || parent.data;
            const index = children.findIndex(d => d.id === data.id);
            children.splice(index, 1);

            this.getCategoryData().then(response2 => {
              if (response2.code == 0) {
                console.log(this.$t('header.log.getCategorySuccess'))
              } else {
                console.log(this.$t('header.log.getCategoryFailed'))
              }
            }).catch(error => {
              console.log(this.$t('header.log.getCategoryFailed'))
            })
          } else {
            this.$message.error(this.$t('header.error.deleteCategoryFailed'))
          }
        }).catch(error => {
          this.$message.error(this.$t('header.error.deleteCategoryFailed'))
        })
      }).catch(() => {
        this.$message({type: 'info', message: this.$t('header.msgbox.cancel')});
      });
    },
    moveUp(node, data) {
      this.categoryDataBackup.forEach(itemLevel1 => {
        itemLevel1.children.forEach((itemLevel2, index) => {
          if (node.level == 2) {
            const idLevel1 = node.parent.data.id
            // 首先第一级的分类ID匹配，其次第二级ID匹配, 最后索引大于零
            if (idLevel1 == itemLevel1.id && itemLevel2.id == data.id && index > 0) {
              let result = this.moveMaterialCategory(data, index)
              if (result) {
                let tempItem = NvUtils.deepCopy(itemLevel2)
                itemLevel1.children[index] = itemLevel1.children[index - 1]
                itemLevel1.children[index - 1] = tempItem
              } else {
                this.$message.error(this.$t('header.error.moveCategoryFailed'))
              }
            }
          } else if (node.level == 3) {
            if (!NvUtils.isEmpty(itemLevel2.children)) {
              itemLevel2.children.forEach((itemLevel3, index) => {
                const idLevel1 = node.parent.parent.data.id
                const idLevel2 = node.parent.data.id
                // 首先第一级的分类ID匹配，其次第二级ID匹配, 其次第三级ID匹配, 最后索引大于零
                if (idLevel1 == itemLevel1.id && idLevel2 == itemLevel2.id && itemLevel3.id == data.id && index > 0) {
                  let result = this.moveMaterialCategory(data, index)
                  if (result) {
                    let tempItem = NvUtils.deepCopy(itemLevel3)
                    itemLevel2.children[index] = itemLevel2.children[index - 1]
                    itemLevel2.children[index - 1] = tempItem
                  } else {
                    this.$message.error(this.$t('header.error.moveCategoryFailed'))
                  }
                }
              })
            }
          }
        })
      })
      this.categoryData = NvUtils.deepCopy(this.categoryDataBackup)
    },
    moveDown(node, data) {
      let alreadyMoved = false
      this.categoryDataBackup.forEach(itemLevel1 => {
        itemLevel1.children.forEach((itemLevel2, index) => {
          if (node.level == 2) {
            const idLevel1 = node.parent.data.id
            // 首先第一级的分类ID匹配，其次第二级ID匹配, 最后索引小于最后的一个
            if (idLevel1 == itemLevel1.id && itemLevel2.id == data.id && index < itemLevel1.children.length - 1 && !alreadyMoved) {
              let result = this.moveMaterialCategory(data, index + 2)
              if (result) {
                let tempItem = NvUtils.deepCopy(itemLevel2)
                itemLevel1.children[index] = itemLevel1.children[index + 1]
                itemLevel1.children[index + 1] = tempItem
              } else {
                this.$message.error(this.$t('header.error.moveCategoryFailed'))
              }
              alreadyMoved = true
            }
          } else if (node.level == 3) {
            if (!NvUtils.isEmpty(itemLevel2.children)) {
              itemLevel2.children.forEach((itemLevel3, index) => {
                const idLevel1 = node.parent.parent.data.id
                const idLevel2 = node.parent.data.id
                // 首先第一级的分类ID匹配，其次第二级ID匹配, 其次第三级ID匹配, 最后索引小于最后的一个
                if (idLevel1 == itemLevel1.id && idLevel2 == itemLevel2.id && itemLevel3.id == data.id && index < itemLevel2.children.length - 1 && !alreadyMoved) {
                  let result = this.moveMaterialCategory(data, index + 2)
                  if (result) {
                    let tempItem = NvUtils.deepCopy(itemLevel3)
                    itemLevel2.children[index] = itemLevel2.children[index + 1]
                    itemLevel2.children[index + 1] = tempItem
                  } else {
                    this.$message.error(this.$t('header.error.moveCategoryFailed'))
                  }
                  alreadyMoved = true
                }
              })
            }
          }
        })
      })
      this.categoryData = NvUtils.deepCopy(this.categoryDataBackup)
    },
    alter(node, data) {
      data.isShow = !data.isShow
      if (data.isShow == false) {
        let setData = NvUtils.deepCopy(data)
        setData.displayNamezhCN = setData.label
        this.editMaterialCategory(setData)
      }
    },
    updateVisible(node, data) {
      let setData = NvUtils.deepCopy(data)
      setData.visibility = parseInt(setData.visibility) === 1 ? 0 : 1
      this.editMaterialCategory(setData)
    },
    Inp(value, data) {
      data.label = value
    },
    renderContent(h, {node, data, store}) {
      return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
            <span>
              <el-button size="mini" type="text" on-click={() => this.append(data)}
                         v-show={node.level < 3}>{this.$t("header.button.add")}</el-button>
              <el-button size="mini" type="text" on-click={() => this.remove(node, data)}
                         v-show={node.level > 1}>{this.$t("header.button.delete")}</el-button>
              <el-button size="mini" type="text" on-click={() => this.moveUp(node, data)}
                         v-show={node.level > 1}>{this.$t("header.button.moveUp")}</el-button>
              <el-button size="mini" type="text" on-click={() => this.moveDown(node, data)}
                         v-show={node.level > 1}>{this.$t("header.button.moveDown")}</el-button>
              <el-button size='mini' type='text' on-click={() => this.alter(node, data)}
                         v-show={node.level > 1}>{this.$t("header.button.modify")}</el-button>
              <el-button size='mini' type='text' on-click={() => this.updateVisible(node, data)}
                         id={node.data.id}
                         v-show={node.level > 1}>{parseInt(node.data.visibility) === 1 ? this.$t("header.button.hide") : this.$t("header.button.show")}</el-button>
              {
                data.isShow ? (
                    <span>
                      <el-input
                          placeholder={this.$t('header.button.inputContent')}
                          value={data.label}
                          onInput={(a) => this.Inp(a, data)}
                          size="mini"
                          style="width: 150px;margin-left: 5px;"
                          clearable>
                      </el-input>
                    </span>
                ) : (
                    ''
                )
              }
            </span>
          </span>
      );
    },
    handleDragStart(node, ev) {
      console.log('drag start', node);
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      console.log('tree drag enter: ', dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      console.log('tree drag leave: ', dropNode.label);
    },
    handleDragOver(draggingNode, dropNode, ev) {
      console.log('tree drag over: ', dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log('tree drag end: ', dropNode && dropNode.label, dropType);
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log('tree drop: ', dropNode.label, dropType);
    },
    allowDrop(draggingNode, dropNode, type) {
      return false
    },
    allowDrag(draggingNode) {
      return false
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
  },
  async mounted() {

  }
}
</script>

<style scoped lang='scss'>
$mainColor: #ffffff;
.el-header {
  display: flex;
  background-color: $mainColor;
  align-items: center;
  color: white;

  img {
    transform: scale(0.5);
    margin: 0 -10px 0 -63px;
  }
}

.header {
  width: 100%;
  height: 60px;
  background-color: aliceblue;
  position: relative;
  top: 0;
  z-index: 100;
}

.el-row {
  width: 100%;
  height: 60px;
}

.el-dropdown-link {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.85);
}

.el-icon-arrow-down {
  font-size: 12px;
}

.scroll-tree {
  max-height: 400px;
  overflow: auto;
}

::v-deep .el-tree-node__content {
  height: 33px;
}

.breadcrumb-container {
  float: left;
  width: 300px;
}
</style>
