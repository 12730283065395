export default {
    uploadType: {
        oss: 0,
        transcode: 1
    },
    ossType: {
        qiniu: 0,
        tencent: 1,
        amazon: 2,
        ali: 3,
        baidu: 4,
        local: 5,
        huaweicloud: 6,
        ksyun: 7
    },
    uploadModule: {
        bsProject: 'bs_project',
        appProject: 'app_project',
        video: 'resource_video',
        image: 'resource_image',
        audio: 'resource_audio',
        material_music: 'material_music',
        material_theme: 'material_theme',
        material_videofx: 'material_videofx',
        material_captionstyle: 'material_captionstyle',
        material_animatedsticker: 'material_animatedsticker',
        material_videotransition: 'material_videotransition',
        material_particle: 'material_particle',
        material_compoundcaption: 'material_compoundcaption',
        material_font: 'material_font',
        material_arscene: 'material_arscene',
        material_template: 'material_template',
        material_backgroundimage: 'material_back_image',
        material_unknown: 'material_unknown',
        split_video_clip: 'split_video_clip',
    },
    roleType: {
        normalUser: 0,
        admin: 1,
        innerUser: 2,
        bilibiliUser: 3
    }
}
