<template>

  <el-main>
    <el-card>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item :label="$t('job.search.uuid')">
          <el-input v-model="formInline.uuid" class="id" clearable></el-input>
        </el-form-item>
        <el-form-item :label="$t('job.search.jobTypeDisplayName')">
          <el-select v-model="formInline.jobType" clearable :placeholder="$t('job.search.select')"
                     style="width: 200px;float: left;">
            <el-option
                v-for="item in jobTypeArray"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('job.search.createTime')">
          <el-date-picker
              v-model="formInline.timeRange"
              type="datetimerange"
              value-format="yyyy-MM-dd HH:mm:ss"
              :range-separator="$t('job.search.to')"
              :start-placeholder="$t('job.search.startTime')"
              :end-placeholder="$t('job.search.endTime')">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('job.search.status')">
          <el-select v-model="formInline.status" clearable :placeholder="$t('job.search.select')"
                     style="width: 200px;float: left;">
            <el-option
                v-for="item in jobStatusArray"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{ $t('job.search.search') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :data="tableData"
                row-key="uuid"
                :row-class-name="tableRowClassName"
                style="width: 100%">
        <el-table-column prop="jobId" :label="$t('job.table.jobId')"></el-table-column>
        <el-table-column prop="instanceId" :label="$t('job.table.instanceId')"></el-table-column>
        <el-table-column prop="uuid" :label="$t('job.table.uuid')"></el-table-column>
        <el-table-column prop="executeStateDisplayName"
                         :label="$t('job.table.executeStateDisplayName')"></el-table-column>
        <el-table-column prop="executeResultCodeDisplayName"
                         :label="$t('job.table.executeResultCodeDisplayName')"></el-table-column>
        <el-table-column prop="jobTypeDisplayName" :label="$t('job.table.jobTypeDisplayName')"></el-table-column>
        <el-table-column prop="progress" :label="$t('job.table.progress')"></el-table-column>
        <el-table-column prop="title" :label="$t('job.table.title')"></el-table-column>
        <el-table-column prop="initDate" :label="$t('job.table.initDate')"></el-table-column>
        <el-table-column prop="startDate" :label="$t('job.table.startDate')"></el-table-column>
        <el-table-column prop="finishDate" :label="$t('job.table.finishDate')"></el-table-column>
        <el-table-column prop="weight" :label="$t('job.table.weight')"></el-table-column>
        <el-table-column fixed="right"
                         :label="$t('job.table.operation')"
                         align="center"
                         width="100">
          <template slot-scope="scope">
            <el-button @click="retryJob(scope.row)" type="text" size="small">{{ $t('job.table.retryJob') }}</el-button>
            <el-button @click="updateJob(scope.row)" type="text" size="small" :disabled="!canUpdate(scope.row)">{{
                $t('job.table.updateJob')
              }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-card>
      <div class="pagination">
        <el-pagination
            layout="total, sizes, prev, pager, next, jumper"
            :page-size="pageSize"
            :current-page="currentPage"
            @current-change="getJobList"
            @prev-click="getJobList"
            @next-click="getJobList"
            @size-change="handleSizeChange"
            :total="total">
        </el-pagination>
      </div>
    </el-card>

    <el-dialog :title="$t('job.dialog.updateJob.title')" width="30%" :visible.sync="updateJobDialogVisible"
               :close-on-click-modal="false"
               :destroy-on-close="true">
      <el-form :model="jobInfo">
        <el-form-item>
          <el-row>
            <el-col :span="4"><span>{{$t('job.dialog.updateJob.weight')}}</span></el-col>
            <el-col :span="20"><el-input :placeholder="$t('job.dialog.updateJob.weightInput')" v-model="jobInfo.weight" required></el-input></el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="confirmJobInfo" style="width: 100%;">
            {{ $t('job.dialog.updateJob.confirm') }}
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-main>

</template>

<script>
import NvNetwork from "../api/NvNetwork"
import NvUtils from "@/api/NvUtils";

export default {
  name: "NvJob",
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 1000,
      formInline: {
        uuid: '',
        jobType: '',
        timeRange: '',
        status: ''
      },
      jobTypeArray: NvNetwork.jobTypes,
      jobStatusArray: NvNetwork.jobStatus,
      updateJobDialogVisible: false,
      jobInfo: {
        uuid: "",
        weight: 0
      }
    }
  },
  methods: {
    canUpdate(row) {
      return row.canUpdate
    },
    confirmJobInfo() {
      let network = new NvNetwork()
      network.jobUpdateWeight(this.jobInfo.uuid, this.jobInfo.weight).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.$message({message: response.msg, type: 'success'})
          this.getJobList()
        } else {
          this.$message.error(response.msg)
        }
      }).catch(error => {
        console.log(error)
      })
      this.updateJobDialogVisible = false
    },
    updateJob(row) {
      this.jobInfo.uuid = row.uuid
      this.jobInfo.weight = row.weight
      this.updateJobDialogVisible = true
    },
    tableRowClassName({row, rowIndex}) {
      if (!NvUtils.isEmpty(row.children) || !NvUtils.isEmpty(row.hasChildren)) {
        return 'warning-row';
      }
      return '';
    },
    retryJob(row) {
      if (!(row.executeState == NvNetwork.jobExecuteStateFinish && row.executeResultCode == NvNetwork.jobExecuteResultCodeFail)) {
        this.$message.info(this.$t('job.info.jobFinishedOrUndergoing'))
        return
      }
      let network = new NvNetwork()
      network.retryJob(row.uuid).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.$message({
            message: this.$t('job.info.jobRetrying'),
            type: 'success'
          })
        } else {
          this.$message.error(this.$t('job.error.jobRetryFailed'))
        }
      }).catch(error => {
        this.$message.error(this.$t('job.error.jobRetryFailed'))
      })
    },
    onSubmit() {
      this.currentPage = 1;
      this.getJobList(this.currentPage, this.pageSize)
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getJobList(this.currentPage, this.pageSize)
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getJobList(1, this.pageSize)
    },
    async getJobList(pageNum, pageSize) {
      if (NvUtils.isEmpty(pageNum)) {
        pageNum = 1
      }
      if (NvUtils.isEmpty(pageSize)) {
        pageSize = this.pageSize
      }

      const {uuid, jobType, timeRange, status} = this.formInline
      if (uuid, jobType, timeRange, status) this.currentPage = 1
      let startTime = null
      let endTime = null
      if (timeRange) {
        startTime = timeRange[0]
        endTime = timeRange[1]
      }

      let network = new NvNetwork()
      network.getJobList(pageNum, pageSize, uuid, jobType, startTime, endTime, status).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.setJobList(response.data)
        } else {
          this.$message.error(this.$t('job.error.jobListFailed'))
        }
      }).catch(error => {
        this.$message.error(this.$t('job.error.jobListFailed'))
      })
    },
    async setJobList(data) {
      this.tableData = []
      this.total = data.total
      if (NvUtils.isEmpty(data.elements)) {
        return
      }
      for (let i = 0; i < data.elements.length; i++) {
        let item = data.elements[i]
        let info = {
          jobId: item.id,
          executeState: item.executeState,
          executeStateDisplayName: this.$t(NvUtils.getExecuteStateDisplayName(item.executeState)),
          jobType: item.type,
          jobTypeDisplayName: this.$t(NvUtils.getJobTypeDisplayName(item.type)),
          progress: item.taskProgress,
          title: item.taskName,
          instanceId: item.instanceId,
          uuid: item.uuid,
          callbackHost: item.callbackHost,
          executeServerUrl: item.executeServerUrl,
          executeResultCode: item.executeResultCode,
          executeResultCodeDisplayName: this.$t(NvUtils.getExecuteResultCodeDisplayName(item.executeResultCode)),
          executeResultMsg: item.executeResultMsg,
          errorCount: item.errorCount,
          initDate: NvUtils.timestampToTime(item.initDate / 1000),
          startDate: NvUtils.timestampToTime(item.startDate / 1000),
          finishDate: NvUtils.timestampToTime(item.finishDate / 1000),
          lastDate: item.lastDate,
          parentId: item.parentId,
          specialType: item.specialType,
          taskSource: item.taskSource,
          callbackState: item.callbackState,
          callbackErrorCount: item.callbackErrorCount,
          dataDetail: item.dataDetail,
          executeResultDetail: item.executeResultDetail,
          weight: item.weight,
          canUpdate: true
        }
        if (item.hasChildren) {
          let network = new NvNetwork()
          let response = await network.jobChildrenList(item.uuid)
          if (response.code == NvNetwork.responseCodeSuccess) {
            let children = []
            response.data.forEach(item => {
              let childInfo = NvUtils.deepCopy(item)
              childInfo.executeStateDisplayName = this.$t(NvUtils.getExecuteStateDisplayName(item.executeState))
              childInfo.jobTypeDisplayName = this.$t(NvUtils.getJobTypeDisplayName(item.type))
              childInfo.executeResultCodeDisplayName = this.$t(NvUtils.getExecuteResultCodeDisplayName(item.executeResultCode))
              childInfo.initDate = NvUtils.timestampToTime(item.initDate / 1000)
              childInfo.startDate = NvUtils.timestampToTime(item.startDate / 1000)
              childInfo.finishDate = NvUtils.timestampToTime(item.finishDate / 1000)
              childInfo.progress = item.taskProgress
              childInfo.title = item.taskName
              childInfo.jobId = item.id
              childInfo.canUpdate = false
              children.push(childInfo)
            })
            info.children = children
          } else {
            this.$message.error(this.$t('job.error.getChildJobFailed'))
          }
        }
        this.tableData.push(info)
      }
      console.log(this.tableData)
    }
  },
  async mounted() {
    this.getJobList(null, null)
  }
}
</script>

<style scoped lang="scss">
.id, .worker-id, .status, .type {
  width: 200px;
}

.start-time, .end-time {
  width: 150px;
}

.pagination {
  text-align: center;
}

::v-deep .el-form-item {
  margin-bottom: 0px;
}

.el-card {
  margin-bottom: 20px;
}

::v-deep .el-dialog .el-form-item {
  margin-bottom: 10px;
}
</style>
