import Vue from 'vue'

import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router'

import i18n from "@/i18n";
import store from '@/store'
import router from "@/router";

Vue.config.productionTip = false

ElementUI.Dialog.props.lockScroll.default = false;

Vue.use(ElementUI);
Vue.use(VueRouter)

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
