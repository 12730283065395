<template>
  <el-main>
    <el-card>
      <el-form :model="search">
        <el-form-item>
          <el-button type="primary" @click="onSubmit">{{ $t('splitVideo.search.addNews') }}</el-button>
          <el-button type="primary" @click="onSearch">{{ $t('splitVideo.search.search') }}</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card>
      <el-table :data="newsList" style="width: 100%"
                :span-method="objectSpanMethod"
                :empty-text="$t('header.dialog.emptyText')">
        <el-table-column :label="$t('splitVideo.table.name')"
                         align="center"
                         width="180">
          <template slot-scope="scope">
            <el-row>{{scope.row.name}}</el-row>
            <el-row><el-button @click="addMiddleClip(scope.row)" type="text" size="small">{{ $t('splitVideo.table.addMiddleClip') }}</el-button></el-row>
            <el-row><el-button @click="deleteNews(scope.row)" type="text" size="small">{{ $t('splitVideo.table.deleteNews') }}</el-button></el-row>
          </template>
        </el-table-column>
        <el-table-column
            :sortable="false"
            align="center"
            v-for="header in headers"
            :key="header.prop"
            :prop="header.prop"
            :label="header.label"
            :min-width="header.minWidth"
            :width="header.width">
        </el-table-column>
        <el-table-column fixed="right"
                         :label="$t('splitVideo.table.operation')"
                         align="center"
                         width="180">
          <template slot-scope="scope">
            <el-button @click="addClip(scope.row)" type="text" size="small" :disabled="scope.row.realClip">{{ $t('splitVideo.table.addClip') }}</el-button>
            <el-button @click="deleteClip(scope.row)" type="text" size="small" v-if="!showButton(scope.row)">{{ $t('splitVideo.table.deleteClip') }}</el-button>
            <el-button @click="deleteMiddleClip(scope.row)" type="text" size="small" v-if="showButton(scope.row)">{{ $t('splitVideo.table.deleteClip') }}</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog :title="$t('splitVideo.dialog.addNews.title')" width="30%" :visible.sync="addNewsDialogVisible"
               :close-on-click-modal="false"
               :destroy-on-close="true">
      <el-form :model="newsInfo">
        <el-form-item>
          <el-input :placeholder="$t('splitVideo.dialog.addNews.titleInput')" v-model="newsInfo.title" required></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="confirmTitle" style="width: 100%;">{{$t('splitVideo.dialog.addNews.confirm')}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog :title="$t('splitVideo.dialog.addClip.title')" width="30%" :visible.sync="addClipDialogVisible"
               :close-on-click-modal="false"
               @close="closeAddClipDialog"
               :destroy-on-close="true">
      <el-form :model="clipInfo" style="padding-bottom: 0px;">
        <el-form-item style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
          <el-upload
              class="upload-demo"
              drag
              action="https://jsonplaceholder.typicode.com/posts/"
              :auto-upload=false
              :on-change="onClipChange"
              :limit="1">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">{{ $t('splitVideo.dialog.addClip.content') }}</div>
          </el-upload>
          <el-progress :percentage="clipInfo.uploadingClip.progress" v-if="clipInfo.uploadingClip.progress > 0"></el-progress>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
          <el-button
              @click="closeAddClipDialog()">{{ $t('splitVideo.dialog.addClip.cancel') }}</el-button>
          <el-button type="primary" @click="onConfirmClip()" style="margin-left: 20px;"
                     :disabled="addClipButtonDisabled">{{ $t('splitVideo.dialog.addClip.confirm') }}</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import NvUtils from "@/api/NvUtils.js";
import NvNetwork from "@/api/NvNetwork.js";

export default {
  name: "NvNewsSplit",
  data() {
    return {
      search: {},
      newsList: [],
      newsGroupList: [],
      headers: [
        {prop: 'typeDisplayName', label: this.$t('splitVideo.table.type'), minWidth: 100},
        {prop: 'title', label: this.$t('splitVideo.table.fileName'), minWidth: 100},
        {prop: 'duration', label: this.$t('splitVideo.table.duration'), minWidth: 100},
        {prop: 'createdAt', label: this.$t('splitVideo.table.createdAt'), minWidth: 100},
        {prop: 'statusDisplayName', label: this.$t('splitVideo.table.status'), minWidth: 100},
      ],
      addNewsDialogVisible: false,
      newsInfo: {
        title: ""
      },
      newsTypeClipStart: "title",
      newsTypeClipStartDisplayName: this.$t('splitVideo.clipType.start'),
      newsTypeClipMiddle: "clip",
      newsTypeClipMiddleDisplayName: this.$t('splitVideo.clipType.middle'),
      newsTypeClipEnd: "tail",
      newsTypeClipEndDisplayName: this.$t('splitVideo.clipType.end'),
      addClipDialogVisible: false,
      clipInfo: {
        uploadingClip: {},
      },
      addClipButtonDisabled: true,
      demoType: "news",
      timer: '',
    }
  },
  mounted() {
    this.splitVideoDemoList()
  },
  beforeDestroy() {
  },
  methods: {
    closeAddClipDialog() {
      this.clipInfo.uploadingClip = {}
      this.addClipButtonDisabled = true
      this.addClipDialogVisible = false
    },
    updateStatus() {
      this.splitVideoDemoList()
    },
    onClipChange(file, fileList) {
      let clipFile = new File([file.raw], file.name, {type: file.raw.type})

      let network = new NvNetwork()
      let uuid = NvUtils.generateUUID()
      network.upload(NvNetwork.resourceTypeSplitVideoClip, file.name, uuid, clipFile, data => {
        if (!data.uploadFinish) {
          this.clipInfo.uploadingClip.progress = parseInt((data.progress).toFixed(0))
        } else {
          this.clipInfo.uploadingClip.progress = 100
          this.clipInfo.uploadingClip.packageUrl = data.Location
          this.clipInfo.uploadingClip.title = file.name
          this.clipInfo.uploadingClip.objectId = data.objectId
          console.log("onClipChange", this.clipInfo.uploadingClip)
          this.addClipButtonDisabled = false
        }
      }, error => {
        console.log(error)
      }, true, this.clipInfo.rowData.demoId)
    },
    onConfirmClip() {
      this.splitVideoClipAdd(this.clipInfo.uploadingClip.objectId, this.clipInfo.uploadingClip.packageUrl, this.clipInfo.uploadingClip.title, this.clipInfo.rowData.type)
    },
    onSubmit() {
      this.addNewsDialogVisible = true
    },
    onSearch() {
      this.splitVideoDemoList()
    },
    splitVideoClipAdd(clipId, url, title, clipType) {
      let network = new NvNetwork()
      network.splitVideoClipAdd(clipId, url, title, clipType).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.$message.info(response.message)
          this.splitVideoDemoList()
        } else {
          this.$message.error(response.message)
        }
      })
      this.addClipDialogVisible = false
    },
    splitVideoDemoList(page, pageSize) {
      if (NvUtils.isEmpty(page)) {
        page = 1
      }
      if (NvUtils.isEmpty(pageSize)) {
        pageSize = 10
      }
      let network = new NvNetwork()
      network.splitVideoDemoList(page, pageSize).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.setupNewsList(response.data)
        } else {
          this.$message.error(response.message)
        }
      })
    },
    createClip() {
      let clip = {
        name: "",
        type: "",
        typeDisplayName: "",
        rowspan: 0,
        colspan: 1,
        id: 0,
        demoId: 0,
        status: 0,
        statusDisplayName: this.getStatusDisplayName(0),
        createdAt: "",
        title: "",
        duration: 0,
        realClip: false
      }
      return clip
    },
    createEmptyClipGroup() {
      let clipGroup = []
      let newsClipStart = this.createClip()
      newsClipStart.type = this.newsTypeClipStart
      newsClipStart.typeDisplayName = this.newsTypeClipStartDisplayName
      newsClipStart.rowspan = 3
      newsClipStart.id = NvUtils.generateUUID()
      clipGroup.push(newsClipStart)

      let newsClipMiddle = this.createClip()
      newsClipMiddle.type = this.newsTypeClipMiddle
      newsClipMiddle.typeDisplayName = this.newsTypeClipMiddleDisplayName
      newsClipMiddle.id = NvUtils.generateUUID()
      clipGroup.push(newsClipMiddle)

      let newsClipEnd = this.createClip()
      newsClipEnd.type = this.newsTypeClipEnd
      newsClipEnd.typeDisplayName = this.newsTypeClipEndDisplayName
      newsClipEnd.id = NvUtils.generateUUID()
      clipGroup.push(newsClipEnd)

      return clipGroup
    },
    isClipExistInGroup(clipId, clipGroup) {
      let exist = false
      clipGroup.forEach(clip => {
        if (clip.id == clipId) {
          exist = true
        }
      })
      return exist
    },
    getTypeDisplayName(type) {
      if (type == this.newsTypeClipStart) {
        return this.newsTypeClipStartDisplayName
      } else if (type == this.newsTypeClipMiddle) {
        return this.newsTypeClipMiddleDisplayName
      } else if (type == this.newsTypeClipEnd) {
        return this.newsTypeClipEndDisplayName
      }
    },
    getStatusDisplayName(status) {
      if (status == 0) {
        return this.$t("splitVideo.status.init")
      } else if (status == 1) {
        return this.$t("splitVideo.status.normal")
      } else if (status == 2) {
        return this.$t("splitVideo.status.failed")
      } else if (status == 3) {
        return this.$t("splitVideo.status.hide")
      } else if (status == 5) {
        return this.$t("splitVideo.status.work")
      } else if (status == 100) {
        return this.$t("splitVideo.status.deleted")
      }
    },
    setupNewsList(data) {
      this.newsList = []
      this.newsGroupList = []
      data.elements.forEach(item => {
        if (item.clips.length > 0) {
          let clipGroup = this.createEmptyClipGroup()
          clipGroup.forEach(clip => {
            item.clips.forEach(clipOrigin => {
              clip.name = item.title
              clip.demoId = item.id
              if (clip.type == clipOrigin.clipType) {
                clip.typeDisplayName = this.getTypeDisplayName(clip.type)
                clip.id = clipOrigin.id
                clip.status = clipOrigin.status
                clip.statusDisplayName = this.getStatusDisplayName(clip.status)
                clip.createdAt = clipOrigin.createdAt
                clip.title = clipOrigin.title
                clip.duration = clipOrigin.duration
                clip.realClip = true
              }
            })
          })
          item.clips.forEach(clipOrigin => {
            if (clipOrigin.clipType == this.newsTypeClipMiddle) {
              if (!this.isClipExistInGroup(clipOrigin.id, clipGroup)) {
                let clip = this.createClip()
                clip.name = item.title
                clip.type = clipOrigin.clipType
                clip.typeDisplayName = this.getTypeDisplayName(clipOrigin.clipType)
                clip.id = clipOrigin.id
                clip.demoId = item.id
                clip.status = clipOrigin.status
                clip.statusDisplayName = this.getStatusDisplayName(clip.status)
                clip.createdAt = clipOrigin.createdAt
                clip.title = clipOrigin.title
                clip.duration = clipOrigin.duration
                clip.realClip = true
                clipGroup.splice(1, 0, clip)
                clipGroup[0].rowspan++
              }
            }
          })

          console.log("setupNewsList", clipGroup)
          this.newsGroupList.push(clipGroup)
        } else {
          let clipGroup = this.createEmptyClipGroup()
          clipGroup.forEach(clip => {
              clip.name = item.title
              clip.demoId = item.id
          })
          this.newsGroupList.push(clipGroup)
        }
      })
      this.rebuildNewsList()
    },
    confirmTitle() {
      let network = new NvNetwork()
      network.splitVideoDemoAdd(this.demoType, this.newsInfo.title).then(response => {
        if (response.code == NvNetwork.responseCodeSuccess) {
          this.addNewsDialogVisible = false
          this.splitVideoDemoList()
        } else {
          this.$message.error(response.message)
        }
      })
    },
    rebuildNewsList() {
      this.newsList = []
      this.newsGroupList.forEach(group => {
        group.forEach(clip => {
          this.newsList.push(clip)
        })
      })
    },
    addClip(rowData) {
      this.addClipDialogVisible = true
      this.clipInfo.rowData = rowData
    },
    deleteClip(rowData) {
      this.$confirm(this.$t('splitVideo.confirm.message'), this.$t('splitVideo.confirm.title'), {
        confirmButtonText: this.$t('splitVideo.confirm.confirm'),
        cancelButtonText: this.$t('splitVideo.confirm.cancel'),
        type: 'warning'
      }).then(() => {
        let network = new NvNetwork()
        network.splitVideoClipRemove(rowData.id).then(response => {
          if (response.code == NvNetwork.responseCodeSuccess) {
            this.splitVideoDemoList()
          } else {
            this.$message.error(response.message)
          }
        })
      })
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return {
          rowspan: row.rowspan,
          colspan: row.colspan
        }
      } else {
        return {
          rowspan: 1,
          colspan: 1
        }
      }
    },
    addMiddleClip(rowData) {
      this.newsGroupList.forEach((group, index) => {
        let found = false
        group.forEach(clip => {
          if (rowData.id == clip.id) {
            found = true
          }
        })
        if (found) {
          let newsClipMiddle = this.createClip()
          newsClipMiddle.type = this.newsTypeClipMiddle
          newsClipMiddle.typeDisplayName = this.newsTypeClipMiddleDisplayName
          newsClipMiddle.id = NvUtils.generateUUID()
          newsClipMiddle.demoId = rowData.demoId

          group.splice(group.length-1, 0, newsClipMiddle)
          group[0].rowspan++
          this.$set(this.newsGroupList, index, group)
          this.rebuildNewsList()
        }
      })
    },
    deleteNews(rowData) {
      this.$confirm(this.$t('splitVideo.confirm.message'), this.$t('splitVideo.confirm.title'), {
        confirmButtonText: this.$t('splitVideo.confirm.confirm'),
        cancelButtonText: this.$t('splitVideo.confirm.cancel'),
        type: 'warning'
      }).then(() => {
        let network = new NvNetwork()
        network.splitVideoDemoRemove(rowData.demoId).then(response => {
          if (response.code == NvNetwork.responseCodeSuccess) {
            this.splitVideoDemoList()
          } else {
            this.$message.error(response.message)
          }
        })
      }).catch(() => {
      })
    },
    showButton(rowData) {
      if (rowData.type == this.newsTypeClipMiddle) {
        return true
      } else {
        return false
      }
    },
    deleteMiddleClip(rowData) {
      this.$confirm(this.$t('splitVideo.confirm.message'), this.$t('splitVideo.confirm.title'), {
        confirmButtonText: this.$t('splitVideo.confirm.confirm'),
        cancelButtonText: this.$t('splitVideo.confirm.cancel'),
        type: 'warning'
      }).then(() => {
        this.newsGroupList.forEach((group, index) => {
          let found = false
          group.forEach(clip => {
            if (rowData.id == clip.id) {
              found = true
            }
          })
          if (found) {
            console.log("deleteMiddleClip", group)
              if (rowData.realClip) {
                let network = new NvNetwork()
                network.splitVideoClipRemove(rowData.id).then(response => {
                  if (response.code == NvNetwork.responseCodeSuccess) {
                    this.splitVideoDemoList()
                  } else {
                    this.$message.error(response.message)
                  }
                })
              } else {
                if (group.length > 3) {
                  let indexRemove = 0
                  group.forEach((item, index) => {
                    if (item.id == rowData.id) {
                      indexRemove = index
                    }
                  })
                  if (indexRemove > 0) {
                    group.splice(indexRemove, 1)
                  }
                  this.rebuildNewsList()
                }
              }
            }
        })
      })

    }
   }
}
</script>

<style scoped>
::v-deep .el-form-item {
  margin-bottom: 0px;
}
.el-card {
  margin-bottom: 20px;
}
::v-deep .el-dialog .el-form-item {
  margin-bottom: 10px;
}
</style>
