import VueRouter from "vue-router";
import NvLogin from "@/components/NvLogin";
import NvWelcome from "@/components/NvWelcome";
import NvJob from "@/components/NvJob";
import NvMaterial from "@/components/NvMaterial";
import NvService from "@/components/monitor/NvService.vue";
import NvEmail from "@/components/monitor/NvEmail.vue";
import NvLayout from "@/components/common/NvLayout.vue";
import NvNewsSplit from "@/components/splitVideo/NvNewsSplit.vue";

function requireAuth(to, from, next) {
    console.log(localStorage.userToken)
    if (!!localStorage.userToken) {
        next()
    } else {
        next({
            path: '/login'
        })
    }
}

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: NvLogin
        },
        {
            path: "/console",
            component: NvLayout,
            beforeEnter: requireAuth,
            meta: {title: ''},
            name: "console",
            redirect: "/console/welcome",
            children: [
                {
                    path: "welcome",
                    component: NvWelcome, beforeEnter: requireAuth,
                    meta: { title: 'sideMenu.welcome' }
                },
                {
                    path: 'task',
                    component: NvJob, beforeEnter: requireAuth,
                    meta: { title: 'sideMenu.job' }
                },
                {
                    path: 'material',
                    component: NvMaterial, beforeEnter: requireAuth,
                    meta: { title: 'sideMenu.material' }
                },
            ]
        },
        {
            path: '/login',
            component: NvLogin
        },
        {
            path: '/monitor',
            component: NvLayout, beforeEnter: requireAuth,
            meta: { title: 'sideMenu.monitor.title' },
            children: [
                {
                    path: 'service',
                    component: NvService, beforeEnter: requireAuth,
                    meta: { title: 'sideMenu.monitor.service' },
                },
                {
                    path: 'email',
                    component: NvEmail, beforeEnter: requireAuth,
                    meta: { title: 'sideMenu.monitor.email' },
                }
            ]
        },
        {
            path: '/splitVideo',
            component: NvLayout, beforeEnter: requireAuth,
            meta: { title: 'sideMenu.splitVideo.title' },
            children: [
                {
                    path: 'newsSplit',
                    component: NvNewsSplit, beforeEnter: requireAuth,
                    meta: { title: 'sideMenu.splitVideo.newsSplit' },
                },
            ]
        }
    ]
})

export default router
